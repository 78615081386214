const colors = {
  primary: {
    light: "#00cc5b",
    main: "#008A30",
    dark: "#008c5c",
    contrastText: "#FFFFFF",
  },
  secondary: {
    light: "#FFA347",
    main: "#FF8000",
    dark: "#CC5200",
    contrastText: "#FFFFFF",
  },
  background: {
    main: "#303030",
    default: "#303030",
    paper: "#464646",
  },
  text: {
    //   primary: "#212121",
    //   secondary: "#757575",
    main: "#FFF",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  success: "#4CAF50",
  error: "#F44336",
  warning: "#FFC107",
  info: "#2196F3",
  mode: "dark",
};

export default colors;
