import React, { useState } from "react";
import { Button, Box, Tooltip, Avatar, IconButton, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { isAbsoluteUrl } from "network/helpers";
import { imgServerBaseUrl } from "network/api-routes";
import RevaText from "components/common/RevaText";
import { useLanguage } from "context/LanguageProvider";
import { useIntl } from "react-intl";
import TranslateIcon from "@mui/icons-material/Translate";
import { Logout } from "@mui/icons-material";

const LinksTopBar = ({ userContext, handleLogoutClick }) => {
  const { locale, switchLanguage } = useLanguage();
  const intl = useIntl();
  const user = userContext.user;
  const [anchorEl, setAnchorEl] = useState(null);
  const [langMenuAnchor, setLangMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleLangMenuOpen = (event) => setLangMenuAnchor(event.currentTarget);
  const handleLangMenuClose = () => setLangMenuAnchor(null);

  const handleLanguageChange = (lang) => {
    switchLanguage(lang);
    handleLangMenuClose();
    handleMenuClose();
  };

  return (
    <Box flexGrow={1} display="flex">
      <Box sx={{ flexGrow: 1, display: "flex" }} gap={2}>
        {user && user.organizations ? (
          <Button href="/classifications" edge="end" color="inherit">
            Rankings
          </Button>
        ) : (
          <Button href="/rankings" edge="end" color="inherit">
            Rankings
          </Button>
        )}
      </Box>
      <Box flexGrow={0}>
        {user && (
          <Box>
            <Tooltip title={user.full_name}>
              {user.profile_pic ? (
                <IconButton onClick={handleMenuOpen} sx={{ p: 0 }}>
                  <Avatar
                    alt="avatar"
                    src={isAbsoluteUrl(user.profile_pic) ? user.profile_pic : `${imgServerBaseUrl}/${user.profile_pic}`}
                  />
                </IconButton>
              ) : (
                <RevaText color="inherit" onClick={handleMenuOpen}>
                  {`${user.first_name} ${user.last_name}`}
                </RevaText>
              )}
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {/* Selector de Idiomas dentro del menú */}
              <MenuItem onClick={handleLangMenuOpen}>
                <ListItemIcon>
                  <TranslateIcon />
                </ListItemIcon>
                {intl.formatMessage({ id: "Idioma" })}
              </MenuItem>

              {/* Submenú para cambiar idioma */}
              <Menu anchorEl={langMenuAnchor} open={Boolean(langMenuAnchor)} onClose={handleLangMenuClose}>
                <MenuItem selected={locale === "es"} onClick={() => handleLanguageChange("es")}>
                  <ListItemIcon>🇪🇸</ListItemIcon>
                  {intl.formatMessage({ id: "Español" })}
                </MenuItem>
                <MenuItem selected={locale === "en"} onClick={() => handleLanguageChange("en")}>
                  <ListItemIcon>🇺🇸</ListItemIcon>
                  {intl.formatMessage({ id: "Inglés" })}
                </MenuItem>
              </Menu>

              <MenuItem href="/entrar" onClick={() => handleLogoutClick(userContext)}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <RevaText id="Cerrar sesión" sx={{ textAlign: "center" }} />
              </MenuItem>
            </Menu>
          </Box>
        )}
        {!user && (
          <Button href="/entrar" edge="end" color="inherit">
            Login
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default LinksTopBar;
