import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, TableContainer, IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";

import Paper from "@mui/material/Paper";
import { withSnackbar } from "notistack";
import performRequest from "network/perform-request";
import { classificationEventSystemsIndexRoute } from "network/api-routes";
import RevaText from "components/common/RevaText";

const styles = (theme) => ({
  container: {
    padding: `${theme.spacing(2)} ${theme.spacing(12)}`,
    alignItems: "center",
    // minHeight: "100vh",
    overflowX: "auto",
  },
  headerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  headerText: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  tableContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  createButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(-1.5),
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
  },
  sectionContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    marginBottom: theme.spacing(8),
    boxShadow: "none",
    width: "100%",
  },
  sectionTitleContainer: {
    backgroundColor: theme.palette.action.hover,
    height: 46,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    "&$expanded": {
      maxHeight: 46,
    },
  },

  sectionTitle: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 14,
  },
});

const ClassificationEventsIndex = ({ classes, userContext, enqueueSnackbar, history }) => {
  //a component that shows a list of event systems of classifications. Retrieves from server and shows them.

  const [ready, setReady] = useState(false);
  const [eventSystemsList, setEventSystemsList] = useState(null);

  //on mount, fetch the list of tournaments
  useEffect(() => {
    performRequest("POST", classificationEventSystemsIndexRoute, {}, true, userContext.user ? userContext.user : null)
      .then((response) => {
        if (response.data) {
          setEventSystemsList(response.data);
          setReady(true);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.message, { variant: "error" });
        setReady(true);
      });
  }, [enqueueSnackbar, userContext.user]);

  if (!ready)
    return (
      <Grid container className={classes.container} justifyContent="center">
        <CircularProgress size={24} />
      </Grid>
    );

  const renderEventSystem = (eventSystem) => {
    const eventType = Object.keys(eventSystem.eval_conditions)[0];
    const eventConditions = Object.values(eventSystem.eval_conditions)[0];
    var eventTitle = "?";

    switch (eventType) {
      case "ascentOfClassification":
        eventTitle = "Ascenso";
        break;
      case "descentOfClassification":
        eventTitle = "Descenso";
        break;
      default:
        break;
    }

    return (
      <TableRow key={eventSystem.id}>
        <TableCell align="center" component="th" scope="row">
          {eventSystem.organization_name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {eventSystem.classification_name}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {eventTitle}
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          <Grid container direction="row" justifyContent="space-around" style={{ display: "flex" }}>
            {eventConditions.total_participations ? (
              <div>Total de participaciones: {eventConditions.total_participations}</div>
            ) : null}
            {eventConditions.was_champion ? <div>Total de torneos ganados: {eventConditions.was_champion}</div> : null}
          </Grid>
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => {
              history.push({
                pathname: `/classification-event/${eventSystem.uuid_}`,
                state: { eventSystem },
              });
            }}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid container xs={10}>
        <Paper className={classes.sectionContainer}>
          <Grid container item xs={12} justifyContent="space-between" className={classes.sectionTitleContainer}>
            <RevaText variant="h1" className={classes.sectionTitle}>
              {"Eventos de rankings"}
            </RevaText>
          </Grid>

          <Grid container item xs={12} justifyContent="center" className={classes.tableContainer}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Organización</TableCell>
                    <TableCell align="center">Ranking</TableCell>
                    <TableCell align="center">Evento</TableCell>
                    <TableCell align="center">Condiciones</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{eventSystemsList?.map((eventSystem) => renderEventSystem(eventSystem)) || null}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(withStyles(styles)(ClassificationEventsIndex));
