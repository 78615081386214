const components = {
  MuiCssBaseline: {
    styleOverrides: `
      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        background:rgb(30, 30, 30);
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background:rgb(76, 76, 76);
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background:rgb(128, 128, 128);
      }
    `,
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none", // 🔥 Remueve cualquier background-image aplicado
      },
    },
  },
};

export default components;
