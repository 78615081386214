import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

const CTAButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: 600,
  textTransform: "none",
  padding: "10px 24px",
  transition: "all 0.3s ease",

  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
  "&:active": {
    backgroundColor: theme.palette.secondary.darker || theme.palette.secondary.dark,
  },
  "&:focus": {
    boxShadow: `0 0 0 3px ${theme.palette.secondary.light}`,
  },
}));

export default CTAButton;
