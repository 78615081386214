import React, { Component } from "react";
import {
  Button,
  Grid,
  CircularProgress,
  TextField,
  Avatar,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import { searchUserRoute, assignUserRoute, playerUpdateRoute } from "../../../network/api-routes";
import performRequest from "../../../network/perform-request";
import { withUserContext } from "../../hoc/withUserContext";
import { withSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import AlertDialog from "../../common/AlertDialog";
import RevaText from "components/common/RevaText";
import { injectIntl } from "react-intl";
const styles = (theme) => ({
  modalContainer: {
    width: "100%",
    padding: 30,
    margin: "auto",
    borderRadius: 5,
    minHeight: 500,
  },
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  searchUserContainer: {
    marginTop: theme.spacing(4),
  },
});

class EditPlayerModal extends Component {
  constructor(props) {
    super(props);

    const player = props.player;
    const isAppUser = Boolean(player.playerable_type === "App\\User" && player.playerable_id);

    let user = null;
    if (isAppUser) {
      user = player.playerable;
    }

    const name = player.name ? player.name : "";
    const phoneNumber = player.phone_number ? player.phone_number : "";
    const documentNumber = player.document_number ? player.document_number : "";

    this.state = {
      ready: true,
      name,
      phoneNumber,
      isAppUser,
      user,
      userPhoneNumber: "",
      userDocument: "",
      searchingUser: false,
      selectedUser: null,
      searchUserResult: null,
      searchEmpty: false,
      deleteAlertOpen: false,
      documentNumber,
      selectedTeamId: this.props.participant.team_id,
    };

    this.searchUser = this.searchUser.bind(this);
    this.assignUser = this.assignUser.bind(this);
    this.savePlayer = this.savePlayer.bind(this);
    this.removeAssignedUser = this.removeAssignedUser.bind(this);
  }

  savePlayer() {
    if (this.state.isAppUser) {
      return null;
    }

    this.setState({
      ready: false,
    });
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      playerUpdateRoute,
      {
        player_id: this.props.player.id,
        name: this.state.name,
        phone_number: this.state.phoneNumber,
        document_number: this.state.documentNumber,
        team_id: this.state.selectedTeamId,
      },
      true,
      user
    )
      .then((response) => {
        this.props.enqueueSnackbar("Jugador actualizado", {
          variant: "success",
        });
        this.props.onSave();
      })
      .catch((error) => {
        this.setState({
          ready: true,
        });
        this.props.enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
        console.log(error);
      });
  }

  searchUser() {
    this.setState({
      searchingUser: true,
      searchUserResult: null,
      searchEmpty: false,
    });
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      searchUserRoute,
      {
        phone_number: this.state.userPhoneNumber,
        document_number: this.state.userDocument,
      },
      true,
      user
    )
      .then((response) => {
        console.log("response is", response);
        this.setState({
          searchingUser: false,
          searchUserResult: response.data.data,
          searchEmpty: response.data.data ? false : true,
        });
      })
      .catch((error) => {
        this.setState({
          searchingUser: false,
          searchUserResult: null,
        });
        console.log("error is", error.response);
        if (error.response.status === 404) {
          this.props.enqueueSnackbar("No se encontró un usuario Reva con esos datos", {
            variant: "error",
          });
        } else {
          this.props.enqueueSnackbar(error.response.data.message, {
            variant: "error",
          });
        }

        console.log(error);
      });
  }

  assignUser() {
    this.setState({
      ready: false,
    });

    const { user } = this.props.userContext;
    performRequest(
      "POST",
      assignUserRoute,
      {
        user_id: this.state.searchUserResult.id,
        player_id: this.props.player.id,
        participant_id: this.props.participant.id,
      },
      true,
      user
    )
      .then((response) => {
        this.props.enqueueSnackbar(response.data.message, {
          variant: "success",
        });
        this.props.onSave();
      })
      .catch((error) => {
        this.setState({
          ready: true,
        });
        console.log(error);
      });
  }

  removeAssignedUser() {
    if (!this.state.isAppUser) {
      return null;
    }

    this.setState({
      ready: false,
    });
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      playerUpdateRoute,
      {
        player_id: this.props.player.id,
        playerable_type: null,
        playerable_id: null,
      },
      true,
      user
    )
      .then((response) => {
        this.props.enqueueSnackbar("Cuenta Reva desasociada", {
          variant: "success",
        });
        this.props.onSave();
      })
      .catch((error) => {
        this.setState({
          ready: true,
        });
        this.props.enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
        console.log(error);
      });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes, player, tournament, intl } = this.props;
    const { user } = this.props.userContext;
    const canSearchUsers = user.permissions["players.search-user.assign"];
    const canChangeTeam = tournament?.sport_slug === "soccer";

    if (!this.state.ready) {
      return (
        <Grid container justifyContent="center" alignItems="center" className={classes.modalContainer}>
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <Stack sx={{ p: 4 }}>
        <RevaText id="Editar jugador" variant="h4" sx={{ mb: 2 }} />

        <Stack>
          <Stack direction="row" sx={{ width: "100%", gap: 10, alignItems: "center" }}>
            <Stack sx={{ gap: 2, width: "100%" }}>
              <RevaText variant="h5">Datos</RevaText>

              <TextField
                variant="standard"
                label={intl.formatMessage({ id: "Nombre" })}
                value={this.state.name}
                onChange={this.handleChange("name")}
                disabled={this.state.isAppUser}
              />
              <TextField
                variant="standard"
                label={intl.formatMessage({ id: "Teléfono" })}
                value={this.state.phoneNumber}
                onChange={this.handleChange("phoneNumber")}
                disabled={this.state.isAppUser}
              />
              <TextField
                variant="standard"
                label={intl.formatMessage({ id: "Documento de identidad" })}
                value={this.state.documentNumber}
                onChange={this.handleChange("documentNumber")}
                disabled={this.state.isAppUser}
              />
            </Stack>
            <Avatar className={classes.largeAvatar} key={player.id} src={player.full_profile_pic_url} />
          </Stack>
          <Stack>
            {canChangeTeam && (
              <Grid item xs={12} justifyContent="flex-start">
                <FormControl variant="standard">
                  <InputLabel id="team-select-label">{intl.formatMessage({ id: "Equipo" })}</InputLabel>
                  <Select
                    variant="standard"
                    labelId="team-select-label"
                    id="team-select"
                    label={intl.formatMessage({ id: "Equipo" })}
                    value={this.state.selectedTeamId}
                    onChange={this.handleChange("selectedTeamId")}
                  >
                    {this.props.participants.map((participant) => {
                      return (
                        <MenuItem key={participant.id} value={participant.team_id}>
                          {participant.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {canSearchUsers && (
              <Stack sx={{ mt: 4, gap: 1 }}>
                <RevaText id="Cuenta Reva" variant="h5" />
                <RevaText
                  color="text.secondary"
                  id="Introduce el número de teléfono o documento para buscar un usuario de Reva y vincularlo a este jugador."
                />
                <Stack direction="row" sx={{ gap: 2 }}>
                  <TextField
                    variant="standard"
                    label={intl.formatMessage({ id: "Teléfono" })}
                    value={this.state.userPhoneNumber}
                    onChange={this.handleChange("userPhoneNumber")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.searchUser();
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.searchUser} edge="end" size="large">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    variant="standard"
                    label={intl.formatMessage({ id: "Documento de identidad" })}
                    value={this.state.userDocument}
                    onChange={this.handleChange("userDocument")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.searchUser} edge="end" size="large">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                {this.state.searchingUser && <CircularProgress sx={{ alignSelf: "center", mt: 2 }} />}

                {this.state.searchUserResult && (
                  <Stack direction="row" sx={{ alignItems: "center", mt: 2 }}>
                    <Avatar src={this.state.searchUserResult.full_profile_pic_url} />
                    <RevaText sx={{ ml: 1, mr: 3 }}>{this.state.searchUserResult.full_name}</RevaText>
                    <Button variant="contained" color="primary" onClick={this.assignUser}>
                      {intl.formatMessage({ id: "Vincular usuario" })}
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack direction="row" sx={{ alignSelf: "flex-end", mt: 4 }}>
          {canSearchUsers && this.state.isAppUser && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                this.setState({
                  deleteAlertOpen: true,
                });
              }}
            >
              {intl.formatMessage({ id: "Desvincular Cuenta Reva" })}
            </Button>
          )}
          {!this.state.isAppUser && !this.state.searchUserResult && (
            <Grid>
              <Button variant="contained" color="primary" onClick={this.savePlayer.bind(this)}>
                Guardar
              </Button>
            </Grid>
          )}
        </Stack>
        <AlertDialog
          hideButton
          isOpen={this.state.deleteAlertOpen}
          onConfirm={() => this.setState({ deleteAlertOpen: false }, this.removeAssignedUser)}
          onCancel={() => this.setState({ deleteAlertOpen: false })}
          alertBody={intl.formatMessage({
            id: "¿Estás seguro de desvincular la cuenta de este jugador? Ya no podrá ver los resultados del torneo en su app.",
          })}
          confirmButtonTitle={intl.formatMessage({ id: "Sí, estoy seguro" })}
          cancelButtonTitle={intl.formatMessage({ id: "Volver atrás" })}
        />
      </Stack>
    );
  }
}

export default withSnackbar(withUserContext(withStyles(styles)(injectIntl(EditPlayerModal))));
