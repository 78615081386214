import React, { createContext, useState, useMemo, useContext } from "react";
import { IntlProvider } from "react-intl";
import esMessages from "../locales/es.json";
import enMessages from "../locales/en.json";

const messages = {
  en: enMessages,
  es: esMessages,
};

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const getInitialLocale = () => {
    return localStorage.getItem("locale") || "es";
  };

  const [locale, setLocale] = useState(getInitialLocale);

  const switchLanguage = (newLocale) => {
    setLocale(newLocale);
    localStorage.setItem("locale", newLocale);
  };

  const value = useMemo(() => ({ locale, switchLanguage }), [locale]);

  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
