import React, { useContext } from "react";
import UserContext from "../../context/UserContext";

// HOC para componentes de clase
export function withUserContext(Component) {
  return function ContextedComponent(props) {
    return (
      <UserContext.Consumer>{(userContext) => <Component {...props} userContext={userContext} />}</UserContext.Consumer>
    );
  };
}

// Custom hook para componentes funcionales
export function useUserContext() {
  return useContext(UserContext);
}
