import React, { useState } from "react";
import { Card, CardContent, Chip, IconButton, Stack } from "@mui/material";
import { ReactSortable } from "react-sortablejs";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useIntl } from "react-intl";
import SupervisedUserCircleRounded from "@mui/icons-material/SupervisedUserCircleRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import tournamentStates from "constants/tournamentStates";
import tournamentTypes from "constants/tournamentTypes";
import RevaText from "./RevaText";
import { Link } from "react-router-dom";

// 🎯 Componente de tarjeta de torneo (draggable)
const TournamentCard = ({ tournament, disabled }) => {
  const intl = useIntl();
  const status = tournamentStates[tournament.state];
  let state = null;
  if (status) {
    state = status.text;
    if (tournament.state === "pending" && tournament.open_signup) {
      state = "Inscripciones abiertas";
    }
  }

  let tournamentType = null;
  let playersPerGroup = null;
  let advancingPlayers = null;
  if (tournamentTypes[tournament.tournament_type]) {
    tournamentType = intl.formatMessage({ id: tournamentTypes[tournament.tournament_type].name });
    if (tournament.has_group_stage) {
      playersPerGroup = tournament.players_per_group;
      advancingPlayers = tournament.advancing_players_per_group;
    }
  }

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        borderRadius: 2,
        boxShadow: 3,
        mb: 2,
      }}
      data-id={tournament.id} // Necesario para react-sortablejs
      className="sortable-item"
    >
      {/* Icono de Drag */}

      <IconButton sx={{ cursor: "grab", mr: !disabled ? 2 : 0 }} className="drag-handle">
        {!disabled && <DragHandleIcon />}
      </IconButton>

      {/* Información del torneo */}
      <CardContent sx={{ flexGrow: 1, p: 0 }}>
        <RevaText variant="h5">{tournament.name}</RevaText>

        {tournament.has_group_stage && (
          <RevaText id="groups.then" values={{ playersPerGroup, advancingPlayers, tournamentType }} />
        )}
        {!tournament.has_group_stage && (
          <RevaText id={tournamentType} values={{ playersPerGroup, advancingPlayers, tournamentType }} />
        )}

        <RevaText id="category.name" values={{ categoryName: tournament.category?.data?.name }} />

        {/* <RevaText id={tournament.has_group_stage ? "groups.then" : tournamentType} variant="body2" color="text.secondary" mt={1} /> */}

        <Stack direction="row" sx={{ mt: 1, alignItems: "center" }}>
          <CalendarMonthIcon />
          <RevaText
            id="start.date"
            values={{ startDate: intl.formatDate(tournament.start_at) }}
            variant="body2"
            ml={1}
          />
        </Stack>
      </CardContent>

      {/* Estado del torneo */}
      <Stack alignItems="flex-end">
        <Chip
          icon={<SupervisedUserCircleRounded />}
          label={`${tournament.participants_count || 0} ${tournament.signup_cap ? `/ ${tournament.signup_cap}` : ""}`}
          variant="outlined"
        />
        <Chip
          label={intl.formatMessage({ id: state })}
          color={state === "Inscripciones abiertas" ? "success" : state === "En curso" ? "warning" : "default"}
          sx={{ mt: 1 }}
        />
      </Stack>
    </Card>
  );
};

// 🎯 Componente de lista ordenable con `ReactSortable`
const SortableTournamentList = ({ tournaments, disabled, onReorder }) => {
  const [items, setItems] = useState(
    tournaments.map((t, index) => ({ ...t, order: index })) // Asegura que cada torneo tenga un `order`
  );

  return (
    <ReactSortable
      list={items}
      setList={(newState) => {
        setItems(newState);
        onReorder(newState); // Callback para actualizar en el backend
      }}
      animation={200} // Suaviza la animación del drag
      handle=".drag-handle" // Solo permite arrastrar desde el ícono
      disabled={disabled}
    >
      {items.map((tournament) => (
        <Link key={tournament.id} to={`/torneo/${tournament.uuid_}`}>
          <TournamentCard tournament={tournament} disabled={disabled} />
        </Link>
      ))}
    </ReactSortable>
  );
};

export default SortableTournamentList;
