import React, { Component } from "react";
import Header from "../components/Header/Header";

import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import { withUserContext } from "./hoc/withUserContext";
import ErrorBoundary from "./common/ErrorBoundary";
import AsyncComponent from "./common/AsyncComponent";
import Competition from "./pages/Competition/Competition";
import CompetitionTournaments from "./pages/Competition/CompetitionTournaments";
import EditCompetition from "./pages/Competition/EditCompetition";
import CompetitionCalendar from "./pages/Competition/CompetitionCalendar";
import Playground from "./pages/Playground";
import CompetitionAdmins from "./pages/Competition/CompetitionAdmins";
import CompetitionEstablishments from "./pages/Competition/CompetitionEstablishments";
import StatsBySport from "./pages/Stats/StatsBySport";
import ClassificationsIndex from "./pages/Classification/ClassificationsIndex";
import CreateClassification from "./pages/Classification/CreateClassification";
import PunctuationIndex from "./pages/Classification/PunctuationIndex";
import CreatePunctuationSystem from "./pages/Classification/CreatePunctuationSystem";
import ClassificationEventsIndex from "./pages/Classification/ClassificationEventsIndex";
import CreateClassificationEvent from "./pages/Classification/CreateClassificationEvent";
import RankingImage from "./pages/Classification/RankingImage";
import CreateTournamentWizard from "./pages/Tournament/CreateTournamentWizard";

const AsyncHome = AsyncComponent(() => import("./pages/Home/Home"));
const AsyncTournament = AsyncComponent(() => import("./pages/Tournament/Tournament"));
const AsyncPageWithDrawer = AsyncComponent(() => import("./pages/PageWithDrawer"));
const AsyncClassificationDrawer = AsyncComponent(() => import("./pages/ClassificationDrawerPage"));
const AsyncCreateCompetitionWizard = AsyncComponent(() => import("./pages/Competition/CreateCompetitionWizard"));

const AsyncGeneralError = AsyncComponent(() => import("./pages/ErrorPages/500"));
const AsyncNotFound = AsyncComponent(() => import("./pages/ErrorPages/404"));
const AsyncSingIn = AsyncComponent(() => import("./pages/SignIn/SignInSide"));
const AsyncSignUp = AsyncComponent(() => import("./pages/SignIn/SignUp"));
const AsyncForgotPassword = AsyncComponent(() => import("./pages/SignIn/ForgotPassword"));
const AsyncLanding = AsyncComponent(() => import("./pages/Landing"));
const AsyncRankingList = AsyncComponent(() => import("./pages/Classification/RankingsList"));
const AsyncRanking = AsyncComponent(() => import("./pages/Classification/Ranking"));
const AsyncClassificationShow = AsyncComponent(() => import("./pages/Classification/ClassificationShow"));
const AsyncPunctuationSystemShow = AsyncComponent(() => import("./pages/Classification/PunctuationSystemShow"));

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return isLoggedIn ? (
        <ErrorBoundary {...props}>
          <Component {...props} />
        </ErrorBoundary>
      ) : (
        <Redirect
          to={{
            pathname: `/signin`,
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);
class AppRouter extends Component {
  render() {
    const { userContext } = this.props;
    return (
      <React.Fragment>
        {userContext.userReady && (
          <Router>
            <div>
              {this.props.children}
              <Header {...this.props} />
              <Switch>
                <RestrictedRoute
                  exact
                  path="/"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncHome userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/landing"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncLanding userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/signin"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncSingIn userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/register"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncSignUp userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/forgot-password"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncForgotPassword userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/torneo/:id"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncTournament userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/rankings"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncRankingList userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/rankings/:uuid"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncRanking userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/rankings/:uuid/image"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <RankingImage userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />

                {/* Restricted Routes */}

                <RestrictedRoute
                  path="/new-competition"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncCreateCompetitionWizard userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/classifications"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncClassificationDrawer
                          {...props}
                          content={<ClassificationsIndex userContext={userContext} archived={false} {...props} />}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/create-classification"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncClassificationDrawer
                          {...props}
                          content={<CreateClassification userContext={userContext} {...props} />}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/archived-classifications"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncClassificationDrawer
                          {...props}
                          content={<ClassificationsIndex userContext={userContext} archived={true} {...props} />}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/classification/:uuid"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncClassificationShow userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/punctuations"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncClassificationDrawer
                          {...props}
                          content={<PunctuationIndex userContext={userContext} {...props} />}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/create-punctuation"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncClassificationDrawer
                          {...props}
                          content={<CreatePunctuationSystem userContext={userContext} {...props} />}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/punctuation/:uuid"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncPunctuationSystemShow userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/classification-events"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncClassificationDrawer
                          {...props}
                          content={<ClassificationEventsIndex userContext={userContext} {...props} />}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/create-classification-event"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncClassificationDrawer
                          {...props}
                          content={<CreateClassificationEvent userContext={userContext} {...props} />}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/classification-event/:uuid"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncClassificationDrawer
                          {...props}
                          content={<CreateClassificationEvent userContext={userContext} {...props} />}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/evento/:id/locales"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncPageWithDrawer
                          {...props}
                          content={
                            <CompetitionEstablishments userContext={userContext} {...props}></CompetitionEstablishments>
                          }
                        />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  path="/evento/:id/usuarios"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncPageWithDrawer
                          {...props}
                          content={<CompetitionAdmins userContext={userContext} {...props}></CompetitionAdmins>}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  path="/evento/:id/torneos"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncPageWithDrawer
                          {...props}
                          content={
                            <CompetitionTournaments userContext={userContext} {...props}></CompetitionTournaments>
                          }
                        />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  path="/evento/:id/crear-torneo"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncPageWithDrawer
                          {...props}
                          content={<CreateTournamentWizard userContext={userContext} {...props} />}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  path="/evento/:id/editar"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncPageWithDrawer
                          {...props}
                          content={<EditCompetition userContext={userContext} {...props}></EditCompetition>}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  path="/evento/:id/calendario"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncPageWithDrawer
                          {...props}
                          content={<CompetitionCalendar userContext={userContext} {...props}></CompetitionCalendar>}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  path="/evento/:id"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncPageWithDrawer
                          {...props}
                          content={<Competition userContext={userContext} {...props}></Competition>}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <RestrictedRoute
                  path="/stats-by-sport"
                  isLoggedIn={userContext.loggedIn}
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncPageWithDrawer
                          {...props}
                          content={<StatsBySport userContext={userContext} {...props}></StatsBySport>}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                {/* Error Routes */}
                <Route
                  exact
                  path="/500"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncGeneralError />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/playground"
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <Playground />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  component={(props) => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncNotFound />
                      </ErrorBoundary>
                    );
                  }}
                />
              </Switch>
            </div>
          </Router>
        )}
      </React.Fragment>
    );
  }
}
export default withUserContext(AppRouter);
