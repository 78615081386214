/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, FormControl, CircularProgress, Paper, Divider } from "@mui/material";
import { withStyles } from "@mui/styles";

import {
  classificationCreateRoute,
  classificationUpdateRoute,
  categoriesForTournamentsRoute,
  getUserOrganizationsRoute,
  classificationToogleArchivedRoute,
} from "../../../network/api-routes";

import { Autocomplete } from "@mui/material";
import { withRouter } from "react-router-dom";

import { red } from "@mui/material/colors";
import { find } from "lodash";

import sports from "../../../constants/sports";
import { withUserContext } from "../../hoc/withUserContext";
import performRequest from "../../../network/perform-request";
import { withSnackbar } from "notistack";
import CategoryAutocomplete from "../Tournament/CategoryAutocomplete";
import OrganizationAutocomplete from "./OrganizationAutocomplete";
import RevaText from "components/common/RevaText";

const styles = (theme) => ({
  container: {
    margin: theme.spacing(8),
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    color: theme.palette.text.primary,
  },
  sectionContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    margin: `${theme.spacing(2)} 0px`,
    boxShadow: "none",
  },
  sectionTitle: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 14,
  },
  sectionTitleContainer: {
    backgroundColor: theme.palette.action.hover,
    height: 46,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    "&$expanded": {
      maxHeight: 46,
    },
  },
  sectionBodyContainer: {
    padding: theme.spacing(2),
  },
  formContainer: {
    padding: theme.spacing(4),
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "1rem",
  },
  input: {
    width: "100%",
    marginLeft: 5,
  },
  formRow: {
    marginBottom: theme.spacing(4),
  },
  createButton: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
  },
  bannerPreview: {
    width: 240,
    height: 135,
    maxWidth: 240,
    maxHeight: 135,
    borderRadius: 5,
    objectFit: "cover",
    marginBottom: 5,
  },
  coverPreview: {
    height: 250,
    width: 200,
    maxHeight: 250,
    maxWidth: 200,
    borderRadius: 5,
    objectFit: "cover",
    marginBottom: 5,
  },
  dangerButton: {
    backgroundColor: red[500],
    color: "#FFFFFF",
  },
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(2)} 0px`,
  },
});

const Section = (props) => {
  const { classes } = props;

  return (
    <Paper className={classes.sectionContainer}>
      <Grid container>
        <Grid item xs={12} className={classes.sectionTitleContainer}>
          <RevaText variant="h1" className={classes.sectionTitle}>
            {props.title}
          </RevaText>
        </Grid>
        <Grid item xs={12} className={classes.sectionBodyContainer}>
          {props.children}
        </Grid>
      </Grid>
    </Paper>
  );
};

const FormRow = (props) => {
  const { classes } = props;

  return (
    <Grid container className={classes.formRow} alignItems="center">
      <Grid item xs={5}>
        <RevaText variant="h3" className={classes.label}>
          {props.label}
        </RevaText>
      </Grid>
      <Grid item xs={7}>
        {props.children}
      </Grid>
    </Grid>
  );
};

const ClassificationForm = (props) => {
  const { classes, userContext, classification } = props;
  const { user } = userContext;

  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedSportSlug, setSelectedSportSlug] = useState("soccer");
  const [sportReady, setSportReady] = useState(false);
  const [name, setName] = useState(classification && classification.name ? classification.name : "");
  const [description, setDescription] = useState(
    classification && classification.description ? classification.description : ""
  );
  const [categories, setCategories] = useState([]);
  const [categoriesReady, setCategoriesReady] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsReady, setOrganizationsReady] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [banner, setBanner] = useState(null);
  const [cover, setCover] = useState(null);

  const [bannerUrl, setBannerUrl] = useState(classification ? classification.full_banner_url : null);
  const [coverUrl, setCoverUrl] = useState(classification ? classification.full_cover_url : null);

  const [submitting, setSubmitting] = useState(false);

  const fetchCategories = () => {
    setCategoriesReady(false);
    performRequest(
      "POST",
      categoriesForTournamentsRoute,
      {
        sport_slug: selectedSport ? selectedSport.slug : null,
      },
      true,
      user
    )
      .then((response) => {
        const categories = response.data.data;
        setCategories(categories);
        if (classification?.category_id) {
          const cat = find(categories, function (o) {
            return o.id === classification.category_id;
          });

          if (cat) {
            setSelectedCategory(cat);
          }
        }
        setCategoriesReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchOrganizations = () => {
    setOrganizationsReady(false);
    performRequest("GET", getUserOrganizationsRoute, {}, true, user)
      .then((response) => {
        const organizations = response.data;
        setOrganizations(organizations);
        if (classification?.organization_id) {
          const org = find(organizations, function (o) {
            return o.id === classification.organization_id;
          });
          if (org) {
            setSelectedOrganization(org);
          }
        }
        setOrganizationsReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setPreData = () => {
    if (classification?.sport_id) {
      // find the sport object inside the sports array corresponding to the sport_id of the classification and set it as the selected sport
      const sport = find(sports, function (o) {
        return o.sport_id === classification.sport_id;
      });

      if (sport) {
        setSelectedSport(sport);
        setSelectedSportSlug(sport.slug);
        setSportReady(true);
        fetchCategories();
      } else {
        setSportReady(true);
      }
    } else {
      setSportReady(true);
    }
  };

  useEffect(() => {
    //fetchCategories();
    setPreData();
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (selectedSport) {
      fetchCategories();
    }
  }, [selectedSportSlug, selectedSport]);

  const handleBannerChange = (event) => {
    const fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize > 1) {
      props.enqueueSnackbar("El archivo es demasiado grande", {
        variant: "error",
      });
      event.preventDefault();
      return false;
    }

    setBanner(event.target.files[0]);
    setBannerUrl(URL.createObjectURL(event.target.files[0]));
  };

  const handleCoverChange = (event) => {
    const fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize > 1) {
      props.enqueueSnackbar("El archivo es demasiado grande", {
        variant: "error",
      });
      event.preventDefault();
      return false;
    }

    setCover(event.target.files[0]);
    setCoverUrl(URL.createObjectURL(event.target.files[0]));
  };

  const handleSelectedSportChange = (event, newValue) => {
    setSelectedSport(newValue);
    setSelectedSportSlug(newValue.slug);
  };

  const handleChange = (name) => (event) => {
    switch (name) {
      case "description":
        if (event.target.value.length <= 500) {
          setDescription(event.target.value);
        }
        break;
      case "name":
        if (event.target.value.length <= 100) {
          setName(event.target.value);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (props.onSubmit) {
      props.onSubmit({
        name,
        description,
        selectedSportSlug,
        selectedCategory,
        selectedOrganization,
        banner,
        cover,
      });
    }
  }, [name, description, selectedSportSlug, selectedCategory, selectedOrganization, banner, cover]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    let formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("sport_slug", selectedSportSlug);
    formData.append("category_id", selectedCategory.id);
    formData.append("organization_id", selectedOrganization.id);
    if (banner) {
      formData.append("banner", banner, `${name}-banner.jpg`);
    }

    if (cover) {
      formData.append("cover", cover, `${name}-cover.jpg`);
    }

    const headers = { "Content-Type": "multipart/form-data" };
    const route = classification ? classificationUpdateRoute : classificationCreateRoute;

    if (classification) {
      formData.append("classification_id", classification.id);
    }

    performRequest("POST", route, formData, true, user, null, headers)
      .then((response) => {
        const newClassification = response.data;
        setSubmitting(false);
        if (props.onSubmitResponse) {
          props.onSubmitResponse(newClassification);
        } else {
          props.enqueueSnackbar("Clasificación actualizada con éxito", {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        setSubmitting(false);
        props.enqueueSnackbar(error?.response?.data?.message || "Ha ocurrido un error. Favor revisa tus datos", {
          variant: "error",
        });
        console.log(error);
      });
  };

  const handleToogleArchived = (event) => {
    event.preventDefault();

    const headers = { "Content-Type": "application/json" };

    if (classification) {
      let data = {
        classification_id: classification.id,
      };

      const route = classificationToogleArchivedRoute;

      performRequest("POST", route, data, true, user, null, headers)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          props.enqueueSnackbar("Ha ocurrido un error al archivar la clasificación", {
            variant: "error",
          });
          console.log(error);
        });
    }
  };

  const renderOrganizationAutocomplete = () => {
    if (!organizationsReady) {
      return <CircularProgress size={24} />;
    }

    return (
      <>
        <OrganizationAutocomplete
          organizations={organizations}
          defaultValue={selectedOrganization}
          onOrganizationSelected={(category) => {
            setSelectedOrganization(category);
          }}
        />
        {selectedOrganization ? (
          <RevaText variant={"caption"}>Elige una organización para el ranking (requerido)</RevaText>
        ) : (
          <RevaText variant="caption" style={{ color: red[500] }}>
            Elige una organización para el ranking (requerido)
          </RevaText>
        )}
      </>
    );
  };

  const renderCategoryAutocomplete = () => {
    if (!selectedSport) {
      return <RevaText variant="caption">Debes seleccionar un deporte primero</RevaText>;
    }

    if (!categoriesReady) {
      return <CircularProgress size={24} />;
    }

    return (
      <>
        <CategoryAutocomplete
          categories={categories}
          defaultValue={selectedCategory}
          onCategoryAdded={(category) => {
            setSelectedCategory(category);
          }}
          onCategorySelected={(category) => {
            setSelectedCategory(category);
          }}
        />
        {selectedCategory ? (
          <RevaText variant={"caption"}>Puedes elegir una categoría oficial o crear una (requerido)</RevaText>
        ) : (
          <RevaText variant="caption" style={{ color: red[500] }}>
            Puedes elegir una categoría oficial o crear una (requerido)
          </RevaText>
        )}
      </>
    );
  };

  return (
    <Grid container className={classes.formContainer}>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <RevaText variant="h1" className={classes.title}>
          Editar detalles
        </RevaText>
        <Section title="Información básica" {...props}>
          <FormRow label="Nombre del ranking" {...props}>
            <FormControl variant="standard" margin="normal" required fullWidth>
              <TextField
                variant="standard"
                value={name}
                onChange={handleChange("name")}
                className={classes.input}
                required
              />
            </FormControl>
          </FormRow>
          <FormRow label="Descripción" {...props}>
            <TextField
              variant="standard"
              multiline
              maxRows={4}
              value={description || ""}
              onChange={handleChange("description")}
              className={classes.input}
              placeholder="Aquí puedes dar más detalles sobre esta categoría, como el nivel de juego esperado, las formas de puntuación, y todo lo que pueda ser de utilidad para los jugadores"
            />
          </FormRow>

          <FormRow label="Banner del ranking" {...props}>
            {bannerUrl && (
              <Grid container justifyContent="center">
                <img className={classes.bannerPreview} src={bannerUrl} alt="Imagen no válida"></img>
              </Grid>
            )}
            <input accept="image/jpg" className={classes.input} type="file" onChange={handleBannerChange} />
            <small>
              <i>Imagen en formato JPG. Aspecto: 16:9 Tamaño máximo 800 KB</i>
            </small>
          </FormRow>

          <FormRow label="Cover del ranking" {...props}>
            {coverUrl && (
              <Grid container justifyContent="center">
                <img className={classes.coverPreview} src={coverUrl} alt="Imagen no válida"></img>
              </Grid>
            )}
            <input accept="image/jpg" className={classes.input} type="file" onChange={handleCoverChange} />
            <small>
              <i>Imagen en formato JPG. Aspecto 4:5 Tamaño máximo 800 KB</i>
            </small>
          </FormRow>

          <FormRow label="Deporte" {...props}>
            {sportReady ? (
              <Autocomplete
                defaultValue={selectedSport}
                options={sports}
                getOptionLabel={(option) => option.name}
                style={{ width: "auto" }}
                onChange={handleSelectedSportChange}
                renderInput={(params) => (
                  <TextField {...params} className={classes.input} label="Deporte" variant="outlined" required={true} />
                )}
                disableClearable={true}
              />
            ) : (
              <CircularProgress size={24} />
            )}
          </FormRow>

          {categories && (
            <FormRow label="Categoría" {...props}>
              {renderCategoryAutocomplete()}
            </FormRow>
          )}

          {organizations && (
            <FormRow label="Organización" {...props}>
              {renderOrganizationAutocomplete()}
            </FormRow>
          )}
        </Section>

        {!props.onSubmit && (
          <Grid item xs={12} container justifyContent="flex-end">
            <Button disabled={submitting || !selectedCategory} type="submit" className={`cta ${classes.createButton}`}>
              {submitting && <CircularProgress size={24} />}
              {classification ? "Guardar cambios" : "Guardar y continuar"}
            </Button>
          </Grid>
        )}
      </form>
      <>
        {props.classification !== undefined ? (
          <Grid item xs={12}>
            <Divider light className={classes.divider} />
            <>
              {props.classification.archived ? (
                <Button
                  variant="contained"
                  className={`${classes.successButton}`}
                  size="small"
                  onClick={handleToogleArchived}
                >
                  Habilitar Ranking
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={`${classes.dangerButton}`}
                  size="small"
                  onClick={handleToogleArchived}
                >
                  Archivar Ranking
                </Button>
              )}
            </>
          </Grid>
        ) : null}
      </>
    </Grid>
  );
};

export default withSnackbar(withRouter(withUserContext(withStyles(styles)(ClassificationForm))));
