import React from "react";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import theme from "./index";

import { useLocalStorage } from "../hooks/useLocalStorage.js";
import SnackbarWrapper from "theme/snackbarWrapper";

const RevaThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useLocalStorage("darkMode", true);

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (index === 1) {
      return React.cloneElement(child, {
        handleThemeChange,
        darkMode,
      });
    }
    return child;
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarWrapper>{childrenWithProps}</SnackbarWrapper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default RevaThemeProvider;
