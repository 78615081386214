import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import RevaThemeProvider from "./theme/themeProvider";
import axios from "axios";

// Configuración de axios
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Obtener el elemento raíz
const container = document.getElementById("root");
const root = createRoot(container);

// Renderizar usando createRoot
root.render(
  <React.StrictMode>
    <RevaThemeProvider>
      <App />
    </RevaThemeProvider>
  </React.StrictMode>
);

// Registrar o desregistrar el service worker
serviceWorker.unregister();
