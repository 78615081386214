import React, { Component } from "react";
import AppRouter from "./components/AppRouter";
import UserContext from "./context/UserContext";
import { getUser } from "./network/user";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet } from "react-helmet";
import { Crisp } from "crisp-sdk-web";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LanguageProvider } from "context/LanguageProvider";

import axios from "axios";
axios.defaults.withCredentials = true;

class App extends Component {
  constructor(props) {
    super(props);
    this.changeUser = (user, callback) => {
      const newState = {
        user: user,
        loggedIn: user ? true : false,
      };
      localStorage.setItem("isLoggedIn", true);
      this.setState(newState);
    };

    this.state = {
      user: null,
      changeUser: this.changeUser,
    };

    Crisp.configure("f2aebf52-501c-4b84-aba7-a2ff4158ebc4", {
      autoload: false,
    });
  }

  componentDidMount() {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      getUser()
        .then((response) => {
          const user = response.data.data;
          this.setState({
            user,
            userReady: true,
            loggedIn: true,
          });

          // Crisp User Info
          Crisp.load();
          Crisp.user.setEmail(user.email);
          Crisp.user.setPhone(user.cellphone_number);
          Crisp.user.setNickname(user.full_name);
          Crisp.session.setData({
            user_id: user.id,
            user_email: user.email,
            user_name: user.full_name,
            user_role: user.role,
          });
        })
        .catch(() => {
          localStorage.setItem("isLoggedIn", false);
          this.setState({
            user: null,
            userReady: true,
            loggedIn: false,
          });
        });
    } else {
      this.setState({
        user: null,
        userReady: true,
        loggedIn: false,
      });
    }
  }

  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <LanguageProvider>
          <DndProvider backend={HTML5Backend}>
            <Helmet>
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>

            <UserContext value={this.state}>
              <AppRouter {...this.props} />
            </UserContext>
          </DndProvider>
        </LanguageProvider>
      </LocalizationProvider>
    );
  }
}

export default App;
