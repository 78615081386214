import React, { Component } from "react";
import performRequest from "../../../network/perform-request";
import { tournamentsCategoriesStats, officialCategoriesForSport } from "../../../network/api-routes";
import { CircularProgress, Grid, Paper, Select, MenuItem, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";

import { forEach } from "lodash";
import RevaText from "components/common/RevaText";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  title: {
    [theme.breakpoints.down("xl")]: {
      fontSize: 50,
    },
    textAlign: "center",
    fontSize: 70,
    fontWeight: 600,
  },
  cardValue: {
    fontSize: "3rem",
    fontWeight: 600,
  },
  cardTitle: {
    fontWeight: 400,
  },
  card: {
    padding: theme.spacing(3),
  },
  iconContainer: {
    backgroundColor: theme.palette.background.default,
    color: "white",
    padding: 10,
    margin: "auto auto 24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: 84,
    height: 84,
    justifyContent: "center",
  },
  icon: {
    fontSize: 50,
  },
  titleContainer: {},
});

const columns = [
  { field: "updated_at", headerName: "Última actualización", flex: 1 },
  {
    field: "name",
    headerName: "Jugador",
    flex: 1,
  },
  {
    field: "document",
    headerName: "Documento de identidad",
    flex: 1,
  },
  {
    field: "highest_position",
    headerName: "Posición más alta",
    flex: 1,
  },

  {
    field: "played_tournaments",
    headerName: "Torneos jugados",
    flex: 1,
  },
];

class StatsBySport extends Component {
  constructor(props) {
    super();

    this.state = {
      selected_sport: "paddle",
      selectedCategory: null,
      categories: null,
      stats: [],
      ready: false,
      refreshing: true,
      rows: [],
      filter: "",
    };

    this.handleCategoryChange = this.handleCategoryChange.bind(this);
  }

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories() {
    const user = this.props.userContext.user;
    performRequest("POST", officialCategoriesForSport, { sport_slug: this.state.selected_sport }, true, user)
      .then((response) => {
        console.log("categories are", response.data.data);
        this.setState(
          {
            categories: response.data.data,
            selectedCategory: response.data && response.data.data.length > 0 ? response.data.data[0] : null,
          },
          () => this.fetchStats()
        );
      })
      .catch((error) => {
        console.log("categories error", error.response);
      });
  }

  fetchStats() {
    const user = this.props.userContext.user;
    performRequest("POST", tournamentsCategoriesStats, { category_id: this.state.selectedCategory.id }, true, user)
      .then((response) => {
        console.log("stats are", response.data.stats);

        this.setState({
          stats: response.data.stats,
          rows: this.parseRows(response.data.stats),
          ready: true,
        });
      })
      .catch((error) => {
        console.log("stats error", error.response);
      });
  }

  handleCategoryChange(category) {
    console.log("category", category.target.value);
    let newCategory = this.state.selectedCategory;
    const value = category.target.value;
    forEach(this.state.categories, (category) => {
      if (category.id === value) {
        newCategory = category;
      }
    });
    this.setState({ ready: false, selectedCategory: newCategory }, () => {
      this.fetchStats();
    });
  }

  handleFilterChange = (event) => {
    this.setState({
      filter: event.target.value,
    });
  };

  parseRows(stats) {
    const rows = stats.map((stat) => {
      let position = "Participante";
      switch (stat.highest_position) {
        case 6:
          position = "Campeón";
          break;
        case 5:
          position = "Finalista";
          break;
        case 4:
          position = "Semi-finalista";
          break;
        case 3:
          position = "Cuartos de final";
          break;
        case 2:
          position = "Octavos de final";
          break;
        case 1:
          position = "Eliminatorias";
          break;
        default:
          break;
      }

      const numberFormatter = Intl.NumberFormat("es-PY");

      return {
        id: stat.id,
        updated_at: stat.updated_at,
        name: stat.user.full_name,
        highest_position: position,
        played_tournaments: stat.played_tournaments,
        document: isNaN(stat.user.document_number) ? "0" : numberFormatter.format(stat.user.document_number),
      };
    });
    return rows;
  }

  render() {
    const { ready, categories, selectedCategory } = this.state;
    const { classes } = this.props;

    if (!ready) {
      return (
        <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
          <CircularProgress size={24}></CircularProgress>
        </Grid>
      );
    }

    return (
      <>
        {selectedCategory ? (
          <>
            <Paper style={{ padding: 20 }}>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                alignContent="center"
                style={{ marginBottom: 20 }}
              >
                <RevaText variant="h3">Categoría:</RevaText>
                <Select
                  variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCategory.id}
                  label={selectedCategory.name}
                  onChange={this.handleCategoryChange}
                  style={{ marginLeft: 10 }}
                >
                  {categories.map((category) => {
                    return <MenuItem value={category.id}>{category.name}</MenuItem>;
                  })}
                </Select>

                <RevaText style={{ marginLeft: 30 }}>Buscar jugador:</RevaText>

                <TextField
                  variant="standard"
                  value={this.state.filter}
                  onChange={this.handleFilterChange}
                  className={classes.input}
                  style={{ marginLeft: 20 }}
                />
              </Grid>

              {this.state.stats.length > 0 && (
                <div style={{ height: 650, width: "100%", cursor: "pointer" }}>
                  <DataGrid
                    hideFooterSelectedRowCount
                    rows={this.state.rows}
                    columns={columns}
                    pageSize={10}
                    disableColumnMenu
                    filterMode="client"
                    filterModel={{
                      items: [
                        {
                          columnField: "name",
                          operatorValue: "contains",
                          value: this.state.filter,
                        },
                        {
                          columnField: "contains",
                          operatorValue: "startsWith",
                          value: this.state.filter,
                        },
                      ],
                    }}
                  />
                </div>
              )}
            </Paper>
          </>
        ) : null}
      </>
    );
  }
}

export default withStyles(styles)(StatsBySport);
