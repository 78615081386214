import React, { memo } from "react";
import PropTypes from "prop-types";
import { Img } from "react-image";
import { withStyles } from "@mui/styles";

const style = () => ({
  placeholder: {
    backgroundColor: "#B6B8B8",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  placeholderImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const Image = ({ classes, src, className, alt = "Image" }) => {
  const placeholder = (
    <div className={classes.placeholder}>
      <Img className={classes.placeholderImage} src={`${src}?w=10&h=10&q=50&blur=2`} alt={`${alt} placeholder`} />
    </div>
  );

  return (
    <div className={className}>
      <Img
        src={src}
        alt={alt}
        loader={placeholder}
        className={className}
        unloader={<div className={classes.placeholder} />}
      />
    </div>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default memo(withStyles(style)(Image));
