// ENV
export const baseDomain = process.env.REACT_APP_BASE_DOMAIN;
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const imgServerBaseUrl = process.env.REACT_APP_IMG_SERVER;
export const siteKey = process.env.REACT_APP_SITE_KEY; // reCAPTCHA

// USER
export const getUserRoute = "/users/show";
export const refreshRoute = "/refresh-token/";
export const signInRoute = "/login/sanctum";
export const signUpRoute = "/register-tournament-admin/";
export const forgotPasswordRoute = "/password-reset/";
export const logoutRoute = "/logout/sanctum";
export const facebookLoginRoute = "/login/facebook/";
export const userAccountsRoute = "/users/user-accounts";
export const userEstablishmentsRoute = "/users/managed-establishments";
export const userManagedEstablishmentsRoute = "competitions/establishments/managed";

// TOURNAMENTS
export const tournamentsIndexRoute = "/tournaments";
export const tournamentsShowRoute = "/tournaments/show";
export const tournamentsCreateRoute = "/tournaments/create";
export const tournamentsStartRoute = "/tournaments/start";
export const tournamentsUpdateRoute = "/tournaments/update";
export const endGroupStageRoute = "/tournaments/end-group-stage";
export const restartTournamentRoute = "/tournaments/restart";
export const deleteTournamentRoute = "/tournaments/destroy";
export const updateRoundRoute = "/tournaments/update-round";
export const tournamentsFinishRoute = "/tournaments/finish";
export const tournamentsPublishRoute = "/tournaments/publish";
export const tournamentGetSpacesRoute = "/tournaments/spaces";
export const tournamentGetSignUpsRoute = "/tournaments/sign-ups";
export const tournamentGetParticipantsRoute = "/tournaments/participants";
export const tournamentGetPlayersStandingsRoute = "/tournaments/players-standings";
export const tournamentGetGroupStageRankingRoute = "/tournaments/group-stage-rankings";
export const tournamentGetFinalRankingsRoute = "/tournaments/final-rankings";
export const tournamentsIndexAllRoute = "tournaments/index-all";

// RANKING ORGANIZATIONS
export const getUserOrganizationsRoute = "/organizations/user-organizations";

// CLASSIFICATIONS PUBLIC
export const classificationsPublicIndexRoute = "/tournaments/classifications-public";
export const getClassificationRoute = "tournaments/classifications-public/show";
export const getRankingImage = "tournaments/classifications-public/ranking-image";
export const getManualAscentClassificationRoute = "tournaments/classifications/manual/ascent";
export const getManualDescentClassificationRoute = "tournaments/classifications/manual/descent";

//CLASSIFICATIONS RESTRICTED
export const classificationsIndexRoute = "/tournaments/classifications";
export const classificationShowRoute = "/tournaments/classifications/show";
export const classificationCreateRoute = "/tournaments/classifications/create";
export const classificationUpdateRoute = "/tournaments/classifications/update";
export const assignTournamentToClassificationRoute = "tournaments/classifications/assign-tournament";
export const updateClassificationTournamentsRoute = "/tournaments/classifications/update-tournaments";
export const classificationEventSystemsIndexRoute = "/tournaments/classifications/events-systems";
export const classificationEventSystemShowRoute = "/tournaments/classifications/events-systems/show";
export const classificationEventSystemCreateRoute = "/tournaments/classifications/events-systems/create";
export const classificationEventSystemUpdateRoute = "/tournaments/classifications/events-systems/update";
export const classificationToogleArchivedRoute = "/tournaments/classifications/toggle-archived";

// PUNCTUATION
export const punctuationIndexRoute = "/tournaments/punctuation-systems";
export const punctuationCreateRoute = "/tournaments/punctuation-systems/create";
export const punctuationUpdateRoute = "/tournaments/punctuation-systems/update";
export const punctuationShowRoute = "/tournaments/punctuation-systems/show";

// PARTICIPANTS
export const participantsIndexRoute = "/participants";
export const participantsShowRoute = "/participants/show";
export const participantsCreateRoute = "/participants/create";
export const participantsUpdateRoute = "/participants/update";
export const participantsAddBulkRoute = "/participants/add-bulk";
export const participantsDeleteRoute = "/participants/destroy";
export const participantsExchangeRoute = "/participants/exchange-positions";
export const participantsReorderRoute = "/participants/reorder";
export const participantsMarkAsPaidRoute = "/participants/mark-as-paid";
export const participantsMoveToTournament = "/participants/move-to-tournament";
export const participantsAddSeedPlayerRoute = "/participants/add-seed-player";

//USER PROFILES
export const userProfileGet = "/users/public-tournaments-profile";

// MATCHES
export const matchesShowRoute = "/matches/show";
export const matchesUpdateRoute = "/matches/update";
export const matchesReopenRoute = "/matches/reopen";
export const matchesUpdateScoreDetailsRoute = "/matches/update-score-details";

// CATEGORIES
export const categoriesCreateRoute = "/categories/create";
export const categoriesUpdateRoute = "/categories/update";
export const categoriesDeleteRoute = "/categories/destroy";
export const categoriesForTournamentsRoute = "/categories/for-tournaments";
export const officialCategoriesForSport = "/categories/official-categories-for-sport";

// VIDEOGAMES
export const searchGamesRoute = "/videogames/search";

// PLAYERS
export const searchUserRoute = "/players/search-user";
export const assignUserRoute = "/players/assign-user";
export const playerUpdateRoute = "/players/update";

// COMPETITIONS
export const competitionsIndexRoute = "/competitions";
export const competitionsShowRoute = "/competitions/show";
export const competitionsCreateRoute = "/competitions/create";
export const competitionsStartRoute = "/competitions/start";
export const competitionsUpdateRoute = "/competitions/update";
export const competitionsTournamentsRoute = "/competitions/tournaments";
export const competitionsGetSignUpsRoute = "/competitions/sign-ups";
export const competitionsToggleArchivedRoute = "/competitions/toggle-archived";
export const competitionsTogglePublishedRoute = "/competitions/toggle-published";
export const competitionsReorderTournamentsRoute = "/competitions/reorder-tournaments";
export const competitionsCalendarEventsRoute = "/competitions/calendar-events";

export const competitionsUsersRoute = "/competitions/users";
export const competitionsUsersInviteRoute = "/competitions/users/invite";
export const competitionUsersCancelInviteRoute = "/competitions/users/cancel-invite";
export const competitionUsersDeleteRoute = "/competitions/users/delete";

export const competitionEstablishmentsAddRoute = "/competitions/establishments/add";
export const competitionEstablishmentsDeleteRoute = "/competitions/establishments/delete";

// TOURNAMENT SIGN UPS
export const tournamentSignUpsShowRoute = "tournament-sign-ups/show";

//STATS
export const tournamentsCategoriesStats = "/tournaments/category-stats";

//LIVESTREAM
export const liveStreamVSBanner = "/live-match/vs-banner";

//CUSTOMERS
export const customersIndexRoute = "/customers/index";

//SUBSCRIPTIONS
export const subscriptionsIndexRoute = "/subscriptions/getProductPlans";
export const subscribeToCompetitionServiceRoute = "/subscriptions/subscribeToCompetitionService";
