import React, { useContext } from "react";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import UserContext from "../../context/UserContext";
import logo from "../../assets/iso-white.svg";
import LinksTopBar from "./LinksTopBar";
import { logoutUser } from "../../network/user";

const Header = () => {
  const location = useLocation();
  const userContext = useContext(UserContext);

  const handleLogoutClick = () => {
    logoutUser(userContext.changeUser);
  };

  // Ocultar el Header en ciertas rutas
  const hiddenRoutes = ["/landing", "/signin", "/register", "/forgot-password"];
  const hideHeader = hiddenRoutes.includes(location.pathname);
  const hideProfile = location.pathname.startsWith("/evento/");

  if (hideHeader) {
    return null;
  }

  return (
    <AppBar position="static" sx={{ background: "#28282a" }}>
      <Container maxWidth="lg" sx={{ height: 64 }}>
        {!hideProfile && (
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            {/* Logo */}
            <Box component={Link} to="/" sx={{ display: "flex", width: 40, cursor: "pointer", mr: 2 }}>
              <img src={logo} alt="Logo" style={{ width: 40, height: "auto" }} />
            </Box>

            {/* Links y Menú */}
            <LinksTopBar userContext={userContext} handleLogoutClick={handleLogoutClick} />
          </Toolbar>
        )}
      </Container>
    </AppBar>
  );
};

export default Header;
