import React from "react";
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import PunctuationForm from "./PunctuationForm";
import RevaText from "components/common/RevaText";

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
});

const CreatePunctuationSystem = (props) => {
  const { classes } = props;
  return (
    <Grid container className={classes.container} xs={12}>
      <Grid item xs={12}>
        <RevaText variant="h1" className={classes.title}>
          Nuevo sistema de puntuación
        </RevaText>
      </Grid>
      <Grid container item xs={12}>
        <PunctuationForm
          {...props}
          onSubmitResponse={() => {
            props.history.push("/punctuations");
          }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CreatePunctuationSystem);
