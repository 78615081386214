import React, { PureComponent } from "react";
import {
  Button,
  Grid,
  TextField,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Dialog,
  CircularProgress,
  DialogActions,
  Stack,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";

import {
  participantsUpdateRoute,
  participantsShowRoute,
  participantsAddSeedPlayerRoute,
} from "../../../network/api-routes";
import { withUserContext } from "../../hoc/withUserContext";
import moment from "moment";
import { withSnackbar } from "notistack";
import ChangeTournamentModal from "./ChangeTournamentModal";
import EditPlayerModal from "./EditPlayerModal";
import { performRequest } from "../../../network/perform-request";
import EditSignUpModal from "../Competition/EditSignUpModal";
import RevaText from "components/common/RevaText";
import { injectIntl } from "react-intl";

const styles = (theme) => ({
  modalContainer: {
    width: "100%",
    padding: 30,
    margin: "auto",
    borderRadius: 5,
  },
  divider: {
    margin: "15px 5px 20px 5px",
  },
  icon: {
    marginLeft: 5,
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  playerSeed: {
    width: 35,
    padding: 5,
    marginRight: 10,
    fontSize: 12,
    textAlign: "center",
    borderRadius: 5,
    backgroundColor: theme.palette.action.hover,
  },
  details: {
    justifyContent: "space-between",
    padding: 5,
  },
  participantRow: {
    cursor: "default !important",
  },
  content: {
    margin: "auto",
  },
  avatar: {
    height: 30,
    width: 30,
    marginRight: 10,
  },
  avatarInGroup: {
    height: 30,
    width: 30,
  },
});

class ParticipantRow extends PureComponent {
  constructor(props) {
    super(props);

    const newName = this.props.participant ? this.props.participant.name : "";
    // const categories = this.props.tournament.categories.data;
    // const categoryId = this.props.participant.category_id;
    this.state = {
      newName,
      // categories,
      // categoryId,
      ready: false,
      open: false,
      openChangeTournamentModal: false,
      openEditPlayerModal: false,
      selectedPlayer: null,
      showNameEdit: false,
      openSignUpModal: false,
      tournament: this.props.tournament,
    };

    this.openChangeTournamentModal = this.openChangeTournamentModal.bind(this);
    this.openEditPlayerModal = this.openEditPlayerModal.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      participantsShowRoute,
      {
        participant_id: this.props.participantId,
      },
      true,
      user
    )
      .then((response) => {
        const participant = response.data.data;
        console.log("participant data", participant, this.props);
        this.setState({
          participant,
          ready: true,
          newName: participant.name,
          showNameEdit: false,
        });
      })
      .catch((error) => {
        this.setState({ ready: true });
        this.props.enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
        console.log(error);
      });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  updatePaymentStatus(paid) {
    let status = "waiting-payment";
    if (paid) {
      status = "completed";
    }

    const { user } = this.props.userContext;
    performRequest(
      "POST",
      participantsUpdateRoute,
      {
        participant_id: this.props.participant.id,
        status,
      },
      true,
      user
    )
      .then((response) => {
        const participant = response.data.data;
        this.props.onParticipantUpdated(participant);
        this.props.enqueueSnackbar(paid ? "Marcado como pagado" : "Marcado como pendiente", {
          variant: "success",
        });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
        console.log(error);
      });
  }

  onAddPlayer() {
    const { user } = this.props.userContext;

    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        participantsAddSeedPlayerRoute,
        {
          amount: 1,
          tournament_id: this.props.tournament.id,
          participant_id: this.state.participant.id,
        },
        true,
        user
      )
        .then((response) => {
          console.log("response add seed player", response);
          this.fetch();
        })
        .catch((error) => {
          console.log("error adding player", error);
          this.setState({ ready: true });
          this.props.enqueueSnackbar(error.response.data.message, {
            variant: "error",
          });
        });
    });
  }

  onSave() {
    const { user } = this.props.userContext;
    this.setState(
      {
        showNameEdit: false,
      },
      () => {
        performRequest(
          "POST",
          participantsUpdateRoute,
          {
            participant_id: this.state.participant.id,
            name: this.state.newName,
          },
          true,
          user
        )
          .then((response) => {
            const participant = response.data.data;
            console.log(response, response.data, participant);
            this.fetch();
          })
          .catch((error) => {
            this.props.enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
            console.log(error);
          });
      }
    );
  }

  renderEditForm() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.details}>
        <Grid item>
          <RevaText
            onClick={() => {
              this.openChangeTournamentModal();
            }}
            variant="h4"
            color="primary"
            className={classes.bulkAddText}
            style={{ cursor: "pointer" }}
          >
            <small>Cambiar de torneo</small>
          </RevaText>
        </Grid>
      </Grid>
    );
  }

  renderUser(participant, user) {
    const { classes } = this.props;
    return (
      <Grid container className={classes.details}>
        <List dense={true}>
          <ListItem>
            <ListItemText
              primary={`Inscripto el ${moment(participant.created_at).format("YYYY-MM-DD [a las] HH:mm")}`}
            />
          </ListItem>
          {user.cellphone_number && (
            <ListItem>
              <ListItemText primary={`Número de teléfono: ${user.cellphone_number}`} />
            </ListItem>
          )}
        </List>
        <Grid container>
          {user.cellphone_number && (
            <React.Fragment>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="a"
                href={`tel:${user.cellphone_number}`}
                size="large"
              >
                <CallIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="a"
                href={`https://wa.me/${user.cellphone_number.replace("+", "")}`}
                target="_blank"
                rel="noopener noreferrer"
                size="large"
              >
                <WhatsAppIcon />
              </IconButton>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    );
  }

  renderTeam(participant, team) {
    const players = team.players;
    return (
      <Grid container spacing={2}>
        {players.map((player) => {
          return (
            <Grid item xs={12} key={player.id}>
              <Paper style={{ margin: 0, borderRadius: 5, padding: 10 }} variant="outlined">
                <Grid container justifyContent="space-between">
                  <Grid item container xs={4} direction="row" alignItems="center">
                    <Avatar
                      key={player.id}
                      alt={player.name}
                      src={player.full_profile_pic_url}
                      style={{ marginRight: 10 }}
                    />
                    <RevaText>{player.name}</RevaText>
                  </Grid>
                  <Grid item container xs={4} justifyContent="flex-end">
                    {player.phone_number && (
                      <React.Fragment>
                        <IconButton
                          color="inherit"
                          aria-label="upload picture"
                          component="a"
                          style={{ fontSize: "inherit" }}
                          href={`tel:${player.phone_number}`}
                          size="large"
                        >
                          <CallIcon />
                        </IconButton>
                        <IconButton
                          color="inherit"
                          aria-label="upload picture"
                          component="a"
                          href={`https://wa.me/${player.phone_number.replace("+", "")}`}
                          target="_blank"
                          style={{ fontSize: "inherit" }}
                          rel="noopener noreferrer"
                          size="large"
                        >
                          <WhatsAppIcon />
                        </IconButton>
                      </React.Fragment>
                    )}
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        this.openEditPlayerModal(player);
                      }}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  openChangeTournamentModal() {
    this.setState({
      openChangeTournamentModal: true,
    });
  }

  openEditPlayerModal(player) {
    this.setState({
      openEditPlayerModal: true,
      selectedPlayer: player,
    });
  }

  renderChangeTournamentDialog() {
    const { participant } = this.state;
    const competitionId = participant.tournament.competition_id;
    return (
      <Dialog
        open={this.state.openChangeTournamentModal}
        onBackdropClick={() => this.setState({ openChangeTournamentModal: false })}
        disableAutoFocus
        fullWidth={true}
        maxWidth="md"
      >
        <ChangeTournamentModal
          competitionId={competitionId}
          participant={participant}
          onSave={() => {
            this.onSave();
            this.setState({ openChangeTournamentModal: false });
          }}
        ></ChangeTournamentModal>
      </Dialog>
    );
  }

  renderEditPlayerDialog() {
    const { participant, tournament } = this.state;
    return (
      <Dialog
        open={this.state.openEditPlayerModal}
        onBackdropClick={() => this.setState({ openEditPlayerModal: false })}
        disableAutoFocus
        fullWidth={true}
        maxWidth="sm"
      >
        <EditPlayerModal
          player={this.state.selectedPlayer}
          participants={this.props.participants}
          participant={participant}
          tournament={tournament}
          onSave={(newParticipant) => {
            this.setState({
              openEditPlayerModal: false,
            });
            this.fetch();
          }}
        />
      </Dialog>
    );
  }

  renderParticipantEditableName() {
    const { intl } = this.props;
    const { participant, showNameEdit } = this.state;
    return (
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <TextField
          sx={{ mr: 1, minWidth: 250 }}
          disabled={!showNameEdit}
          label={intl.formatMessage({ id: "Nombre completo" })}
          defaultValue={participant.name}
          value={this.state.newName}
          onChange={this.handleChange("newName")}
        />
        {showNameEdit ? (
          <Button size="large" color="primary" onClick={this.onSave.bind(this)}>
            {intl.formatMessage({ id: "Guardar" })}
          </Button>
        ) : (
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => {
              this.setState({
                showNameEdit: true,
              });
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
    );
  }

  renderTournamentSignUpDialog() {
    const { participant } = this.state;

    if (!participant.tournament_sign_up) {
      return null;
    }
    return (
      <Dialog
        open={this.state.openSignUpModal}
        onBackdropClick={() => this.setState({ openSignUpModal: false })}
        disableAutoFocus
        fullWidth={true}
        maxWidth="md"
      >
        <EditSignUpModal
          onSave={() => {
            this.setState({
              openSignUpModal: false,
            });
            this.fetch();
          }}
          signUpId={participant.tournament_sign_up.id}
        ></EditSignUpModal>
      </Dialog>
    );
  }

  renderAddPlayer() {
    const { players_per_team } = this.props.tournament;
    const { participant } = this.state;

    if (
      participant &&
      participant.team &&
      participant.team.is_couple === false &&
      participant.team.players.length > 0
    ) {
      return (
        <Grid container item xs={12} alignItems="center" style={{ marginTop: 10 }}>
          <RevaText variant="h3">{`${participant.team.players.length} de ${players_per_team} jugadores`}</RevaText>
          {participant.team.players.length < players_per_team ? (
            <Button
              onClick={() => {
                this.onAddPlayer();
              }}
            >
              Agregar un jugador
            </Button>
          ) : null}
        </Grid>
      );
    }
    return null;
  }

  render() {
    const { classes, intl } = this.props;
    const { participant, ready } = this.state;

    if (!ready) {
      return (
        <Grid container className={classes.modalContainer} justifyContent="center">
          <CircularProgress size={16}></CircularProgress>
        </Grid>
      );
    }
    const user = participant.user && participant.user.data;
    const team = participant.team;

    const tournamentSignUp = participant.tournament_sign_up;

    return (
      <div className={classes.modalContainer}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            {this.renderParticipantEditableName()}
            {this.renderAddPlayer()}
          </Grid>
          <Grid item xs={12} container>
            {user && this.renderUser(participant, user)}
            {team && this.renderTeam(participant, team)}
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={() => {
              this.openChangeTournamentModal();
            }}
          >
            {intl.formatMessage({ id: "Mover a otro torneo" })}
          </Button>

          {tournamentSignUp && (
            <Button
              onClick={() => {
                this.setState({ openSignUpModal: true });
              }}
            >
              {intl.formatMessage({ id: "Detalles de inscripción" })}
            </Button>
          )}
        </DialogActions>

        {this.renderChangeTournamentDialog()}
        {this.renderEditPlayerDialog()}
        {tournamentSignUp && this.renderTournamentSignUpDialog()}
      </div>
    );
  }
}

export default withSnackbar(withUserContext(withStyles(styles)(injectIntl(ParticipantRow))));
