import colors from "./colors";

const typography = {
  fontFamily: "'Ubuntu', 'Roboto', 'Helvetica', 'Arial', sans-serif",
  fontSize: 12,
  h1: {
    fontWeight: "700",
    fontSize: "2rem",
  },
  h2: {
    fontWeight: "700",
    fontSize: "1.75rem",
  },
  h3: {
    fontWeight: "600",
    fontSize: "1.5rem",
  },
  h4: {
    fontWeight: "600",
    fontSize: "1.25rem",
  },
  h5: {
    fontWeight: "500",
    fontSize: "1.125rem",
  },
  h6: {
    fontWeight: "500",
    fontSize: "1rem",
  },
  subtitle1: {
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  body1: { fontSize: "0.875rem" },
  body2: { fontSize: "0.75rem" },
  caption: {
    fontSize: "0.75rem",
    fontWeight: "300",
  },
  captionWarning: {
    fontSize: "0.75rem",
    fontWeight: "300",
    fontColor: colors.error,
  },
  button: {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: colors.primary.contrastText,
    textTransform: "none",
  },
};

export default typography;
