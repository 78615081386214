import React, { Component } from "react";
import performRequest from "../../../network/perform-request";
import { competitionsShowRoute } from "../../../network/api-routes";
import { Box, CircularProgress, Grid, Grid2, Paper, Stack } from "@mui/material";
import { withStyles } from "@mui/styles";
import CloudOutlined from "@mui/icons-material/CloudOutlined";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import MonetizationOnOutlined from "@mui/icons-material/MonetizationOnOutlined";
import PeopleOutline from "@mui/icons-material/PeopleOutline";
import CompetitionSignUps from "./CompetitionSignUps";
import { Link } from "react-router-dom";
import RevaText from "components/common/RevaText";
import { injectIntl } from "react-intl";
import CTAButton from "components/common/CTAButton";

const styles = (theme) => ({
  iconContainer: {
    backgroundColor: theme.palette.background.default,
    color: "white",
    padding: 10,
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
});

const IconCard = (props) => {
  const { title, value, icon, classes } = props;

  return (
    <Grid2
      container
      component={Paper}
      size={{ xs: 12, sm: 3 }}
      sx={{
        p: 2,
        alignItems: "center",
        flexDirection: { xs: "row", sm: "column" },
        gap: { xs: 2, sm: 2 },
        textAlign: { xs: "left", sm: "center" },
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      <Box className={classes.iconContainer} sx={{ height: { xs: 60, md: 80 }, width: { xs: 60, md: 80 } }}>
        {icon}
      </Box>
      <RevaText variant="h2" component="h3">
        {value}
      </RevaText>
      <RevaText id={title} variant="h4" sx={{ flexGrow: 1 }} />
    </Grid2>
  );
};

class Competition extends Component {
  constructor(props) {
    super();

    this.state = {
      id: props.match.params.id,
      ready: false,
      competitions: [],
      selectedIndex: 0,
      meta: null,
    };
  }

  componentDidMount() {
    this.fetch(this.props.userContext.user);
  }

  fetch(user) {
    performRequest("POST", competitionsShowRoute, { competition_uuid: this.state.id }, true, user)
      .then((response) => {
        this.setState({
          competition: response.data.data,
          meta: response.data.meta,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  formatAmount(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  renderCards() {
    const { classes } = this.props;
    const { meta } = this.state;

    return (
      <Grid2 container spacing={2} mt={2}>
        <IconCard
          classes={classes}
          title="Participantes"
          value={meta.participants_count}
          icon={<PeopleOutline sx={{ fontSize: { xs: 30, md: 50 } }}></PeopleOutline>}
        />
        <IconCard
          classes={classes}
          title="Ingresos"
          value={this.formatAmount(meta.sign_ups_amount)}
          icon={<MonetizationOnOutlined sx={{ fontSize: { xs: 30, md: 50 } }}></MonetizationOnOutlined>}
        />
        <IconCard
          classes={classes}
          title="Inscripciones online"
          value={meta.online_sign_ups}
          icon={<CloudOutlined sx={{ fontSize: { xs: 30, md: 50 } }}></CloudOutlined>}
        />
        <IconCard
          classes={classes}
          title="Pagos pendientes"
          value={meta.pending_sign_ups}
          icon={<ErrorOutline sx={{ fontSize: { xs: 30, md: 50 } }}></ErrorOutline>}
        />
      </Grid2>
    );
  }

  render() {
    const { intl } = this.props;
    const { competition } = this.state;
    if (!this.state.ready) {
      return (
        <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
          <CircularProgress size={24}></CircularProgress>
        </Grid>
      );
    }

    const noTournaments = competition.tournaments_count === 0;
    return (
      <>
        {noTournaments && (
          <Box width="100%" sx={{ p: { xs: 4, md: 10 } }} display="grid" gap={8} textAlign="center">
            <Box
              component="img"
              src="/img/welcome.svg"
              alt="Welcome"
              sx={{
                width: "100%", // La imagen ocupa todo el ancho disponible
                maxWidth: "400px", // Evita que la imagen sea más ancha que la pantalla
                height: "auto", // Mantiene la proporción original
                display: "block", // Evita espacios extra en algunos navegadores
                margin: "0 auto", // Centra la imagen horizontalmente
              }}
            />
            <Box display="grid" gap={4}>
              <Box display="grid" gap={2}>
                <RevaText id="event.ready" values={{ eventName: competition.name }} variant="h2" />
                <RevaText
                  id="Ahora puedes agregar torneos, agregar administradores y personalizar los detalles."
                  variant="h5"
                />
              </Box>

              <Link to={`/evento/${this.state.id}/crear-torneo`}>
                <CTAButton size="large">{intl.formatMessage({ id: "Crear un torneo" })}</CTAButton>
              </Link>
            </Box>
          </Box>
        )}

        {!noTournaments && (
          <Stack spacing={2} m={2} sx={{ m: 2, margin: "auto" }}>
            <Box
              className="background background-filter"
              justifyContent="center"
              sx={{
                backgroundImage: `url('${this.state.competition.full_cover_url}')  !important`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                width: "100% !important",
                alignItems: "center",
                backgroundPosition: "center",
                p: { xs: 4, md: 16 },
              }}
            >
              <span>
                <RevaText variant="h1" textAlign="center">
                  {this.state.competition.name}
                </RevaText>
              </span>
            </Box>

            {this.renderCards()}

            <CompetitionSignUps
              competition={competition}
              onUpdate={() => {
                this.fetch();
              }}
            />
          </Stack>
        )}
      </>
    );
  }
}

export default withStyles(styles)(injectIntl(Competition));
