import React, { Component } from "react";
import { CircularProgress, Box } from "@mui/material";
import performRequest from "../../../network/perform-request";
import { competitionsShowRoute } from "../../../network/api-routes";
import EditCompetitionForm from "./EditCompetitionForm";
class EditCompetition extends Component {
  constructor(props) {
    super();

    this.state = {
      id: props.match.params.id,
      ready: false,
      competition: null,
    };
  }

  componentDidMount() {
    this.fetch(this.props.userContext.user);
  }

  fetch(user) {
    performRequest("POST", competitionsShowRoute, { competition_uuid: this.state.id }, true, user)
      .then((response) => {
        this.setState({
          competition: response.data.data,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onSubmitResponse(competition) {
    this.props.history.push(`/evento/${competition.uuid_}`);
  }

  render() {
    return (
      <Box sx={{ width: "100%" }}>
        {!this.state.ready && <CircularProgress />}
        {this.state.ready && this.state.competition && (
          <EditCompetitionForm
            competition={this.state.competition}
            onSubmitResponse={this.onSubmitResponse.bind(this)}
          />
        )}
      </Box>
    );
  }
}

export default EditCompetition;
