import React, { useEffect, useState } from "react";
import performRequest from "../../../network/perform-request";
import { competitionsReorderTournamentsRoute, competitionsTournamentsRoute } from "../../../network/api-routes";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import EmptyPage from "../../common/EmptyPage";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import RevaText from "components/common/RevaText";
import { useIntl } from "react-intl";
import SortableTournamentList from "components/common/SortableTournamentList";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CompetitionTournaments = ({ userContext }) => {
  const intl = useIntl();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [ready, setReady] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [newTournamentsIdsOrder, setNewTournamentsIdsOrder] = useState(null);
  const [oldTournamentsIdsOrder, setOldTournamentsIdsOrder] = useState(null);
  const [reorderDisabled, setReorderDisabled] = useState(true);
  const [reorderReady, setReorderReady] = useState(true);

  useEffect(() => {
    fetchTournaments(userContext.user);
  }, [userContext.user]);

  const fetchTournaments = (user) => {
    performRequest("POST", competitionsTournamentsRoute, { competition_uuid: id }, true, user)
      .then((response) => {
        const tournamentsData = response.data.data;
        const initialOrder = tournamentsData.map((tournament) => tournament.id.toString());
        setTournaments(tournamentsData);
        setOldTournamentsIdsOrder(initialOrder);
        setReady(true);
      })
      .catch((error) => console.log(error));
  };

  const reorderTournaments = () => {
    if (JSON.stringify(oldTournamentsIdsOrder) === JSON.stringify(newTournamentsIdsOrder)) {
      return;
    }
    setReorderReady(false);
    performRequest(
      "POST",
      competitionsReorderTournamentsRoute,
      {
        competition_uuid: id,
        order: newTournamentsIdsOrder,
      },
      true,
      userContext.user
    )
      .then(() => {
        setOldTournamentsIdsOrder(newTournamentsIdsOrder);
        setReorderReady(true);
        enqueueSnackbar(intl.formatMessage({ id: "Torneos reordenados con éxito" }), { variant: "success" });
      })
      .catch(() => {
        setReorderReady(true);
        enqueueSnackbar(intl.formatMessage({ id: "No se pueden reordenar los torneos" }), { variant: "error" });
      });
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <RevaText id="Torneos" variant="h2" />
        <Box>
          {!reorderDisabled && (
            <Button
              sx={{ color: "gray", mr: 1 }}
              onClick={() => {
                setReorderDisabled(true);
                setNewTournamentsIdsOrder(null);
              }}
            >
              {intl.formatMessage({ id: "Cancelar" })}
            </Button>
          )}
          {tournaments.length > 1 && (
            <Button
              disabled={!reorderReady}
              variant="outlined"
              sx={{ color: "primary.light" }}
              onClick={() => {
                if (reorderDisabled) {
                  setReorderDisabled(false);
                } else {
                  setReorderDisabled(true);
                  reorderTournaments();
                }
              }}
            >
              {intl.formatMessage({ id: reorderDisabled ? "Reordenar torneos" : "Guardar cambios" })}
            </Button>
          )}
        </Box>
      </Stack>
      {!ready && <CircularProgress size={24} />}
      {ready && (
        <Box>
          {tournaments.length === 0 && <EmptyPage text="¡Crea tu primer torneo para empezar!" />}
          <Link to={`/evento/${id}/crear-torneo`}>
            <Button
              color="secondary"
              sx={{
                borderStyle: "dashed",
                borderWidth: 2,
                width: "100%",
                height: "100%",
                minHeight: 105,
                mb: 2,
                display: reorderDisabled ? "flex" : "none",
              }}
            >
              <AddCircleOutlineIcon fontSize="large" sx={{ mr: 1 }} />
              <RevaText variant="h4">{intl.formatMessage({ id: "Crear un torneo" })}</RevaText>
            </Button>
          </Link>
          <SortableTournamentList
            tournaments={tournaments}
            disabled={reorderDisabled}
            onReorder={(sortedList) => {
              const newOrder = sortedList.map((tournament) => tournament.id.toString());
              setNewTournamentsIdsOrder(newOrder);
              setTournaments(sortedList);
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default CompetitionTournaments;
