import React, { useState, useEffect, useCallback } from "react";
import { Paper, CircularProgress, Dialog, Button, Box } from "@mui/material";
import performRequest from "../../../network/perform-request";
import { competitionsGetSignUpsRoute, baseDomain } from "../../../network/api-routes";
import { DataGrid } from "@mui/x-data-grid";
import EditSignUpModal from "./EditSignUpModal";
import RevaText from "components/common/RevaText";
import { useIntl } from "react-intl";

const CompetitionSignUps = ({ user, competition, onUpdate }) => {
  const [ready, setReady] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const intl = useIntl();

  const columns = [
    { field: "createdAt", headerName: intl.formatMessage({ id: "Fecha" }), flex: 1 },
    { field: "participant", headerName: intl.formatMessage({ id: "Participante" }), flex: 2 },
    { field: "tournament", headerName: intl.formatMessage({ id: "Torneo" }), flex: 1 },
    { field: "type", headerName: intl.formatMessage({ id: "Origen" }), flex: 1 },
    { field: "paymentType", headerName: intl.formatMessage({ id: "Tipo de pago" }), flex: 1 },
    { field: "paymentStatus", headerName: intl.formatMessage({ id: "Estado de pago" }), flex: 1 },
  ];

  const formattedDate = (date) => {
    return intl.formatDate(new Date(date), {
      weekday: "short", // "Lun", "Tue", etc.
      day: "2-digit",
      month: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const parseRows = (signUps) => {
    return signUps.map((signUp) => ({
      id: signUp.id,
      createdAt: formattedDate(signUp.created_at),
      participant: signUp.participant?.data.display_name || signUp.user.full_name,
      tournament: signUp.tournament?.data.name || intl.formatMessage({ id: "Desconocido" }),
      type: intl.formatMessage({ id: signUp.is_admin ? "Manual" : "Online" }),
      paymentType: intl.formatMessage({ id: signUp.payment_id ? "Pago Online" : "Efectivo / Otro" }),
      paymentStatus: intl.formatMessage({
        id:
          signUp.status === "waiting-payment" ? "⚠ Pendiente" : signUp.status === "expired" ? "✖ Expirado" : "✔ Pagado",
      }),
    }));
  };

  const fetchSignUps = useCallback(async () => {
    try {
      const response = await performRequest(
        "POST",
        competitionsGetSignUpsRoute,
        { competition_uuid: competition.uuid_ },
        true,
        user
      );
      const fetchedSignUps = response.data?.data || [];
      setRows(parseRows(fetchedSignUps));
    } catch (error) {
      console.error("Error fetching sign-ups:", error);
    } finally {
      setReady(true);
    }
  }, [competition, user]);

  useEffect(() => {
    fetchSignUps();
  }, [fetchSignUps]);

  const handleRowSelected = (row) => {
    setSelectedRow(row);
    setOpenSignUpModal(true);
  };

  const handleRowUpdated = () => {
    setOpenSignUpModal(false);
    setSelectedRow(null);
    fetchSignUps();
    onUpdate();
  };

  const openAccountLink = () => {
    const url = `${baseDomain}/account/set-account/${competition.account?.data.id}`;
    console.log("Opening URL:", url);
    window.open(url, "_blank");
  };

  return (
    <Box component={Paper} p={3}>
      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <RevaText id="Inscripciones" variant="h3" sx={{ mr: 4 }} />
        <Button variant="contained" disableElevation size="large" onClick={openAccountLink}>
          {intl.formatMessage({ id: "Ver estado de cuenta asociada" })}
        </Button>
      </Box>

      {!ready ? (
        <Box display="flex" alignItems="center">
          <CircularProgress size={16} sx={{ mr: 2 }} />
          <i>Cargando inscripciones...</i>
        </Box>
      ) : rows.length > 0 ? (
        <DataGrid
          sx={{ cursor: "pointer" }}
          onRowClick={({ row }) => handleRowSelected(row)}
          rows={rows}
          autosizeOnMount
          disableColumnMenu
          density="comfortable"
          columns={columns}
          pageSizeOptions={[10, 25, 50]}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        />
      ) : (
        <RevaText id="Aún no hay inscripciones en este evento." variant="body1" />
      )}

      {selectedRow && (
        <Dialog
          open={openSignUpModal}
          onClose={() => setOpenSignUpModal(false)}
          disableAutoFocus
          fullWidth
          maxWidth="md"
        >
          <EditSignUpModal onSave={handleRowUpdated} signUpId={selectedRow.id} />
        </Dialog>
      )}
    </Box>
  );
};

export default CompetitionSignUps;
