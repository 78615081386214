import RevaText from "components/common/RevaText";
import { useContext, useEffect, useState } from "react";
import UserContext from "context/UserContext";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePicker } from "@mui/x-date-pickers";
import sports from "constants/sports";
import performRequest from "network/perform-request";
import {
  categoriesForTournamentsRoute,
  classificationsIndexRoute,
  deleteTournamentRoute,
  getUserOrganizationsRoute,
  punctuationIndexRoute,
  restartTournamentRoute,
  tournamentsCreateRoute,
  tournamentsUpdateRoute,
} from "network/api-routes";
import CategoryAutocomplete from "./CategoryAutocomplete";
import { SINGLE_ELIMINATION } from "constants/tournamentTypes";
import CurrencySelector from "components/common/CurrencySelector";
import { useParams, useHistory } from "react-router-dom";
import CTAButton from "components/common/CTAButton";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AlertDialog from "components/common/AlertDialog";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useSnackbar } from "notistack";

const {
  Box,
  Paper,
  Autocomplete,
  TextField,
  Stack,
  Tooltip,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Grid2,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} = require("@mui/material");

const SectionContainer = ({ title, children }) => (
  <Paper elevation={2} sx={{ p: 4, borderRadius: 2 }}>
    {title && <RevaText id={title} variant="h4" mb={4} />}
    {children}
  </Paper>
);

function CreateTournamentWizard({ tournament, onSubmitResponse = null }) {
  const { user } = useContext(UserContext);
  const intl = useIntl();
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsReady, setOrganizationsReady] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [classifications, setClassifications] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [punctuationSystems, setPunctuationSystems] = useState([]);
  const [selectedPunctuationSystem, setSelectedPunctuationSystem] = useState(null);
  const [selectedSport, setSport] = useState(tournament?.sport);
  const [selectedCategory, setCategory] = useState(tournament?.category?.data);
  const [startDate, setStartDate] = useState(tournament ? dayjs(tournament.start_at) : null);
  const [restartDialogOpen, setRestartDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(
    Object.assign(
      {
        competition_id: id,
        // General information
        name: "",
        description: "",
        sport_slug: null,
        category_id: null,
        start_at: null,
        // Tournament format
        has_group_stage: false,
        tournament_type: SINGLE_ELIMINATION,
        modality: "",
        players_per_team: null,
        players_per_group: "",
        advancing_players_per_group: "",
        advance_participants_when_group_stage_is_over: false,
        default_sets: 1,
        is_home_and_away: false,
        enforce_min_participants_per_group: false,
        perfect_bracket: false,
        // Ranking & Classification
        classification_id: null,
        punctuation_system_id: null,
        use_ranking_system: false,
        // Rules & Participants
        signup_cap: null,
        allow_self_reporting: false,
        requires_document_for_players: false,
        // Visibility & Registration
        signup_price: null,
        currency_code: "USD",
        show_bracket: true,
        open_signup: true,
      },
      tournament ?? {}
    )
  );

  const stepTitles = [
    "Información general",
    "Formato del torneo",
    "Ranking y Clasificación",
    "Reglas y Participantes",
    "Visibilidad y Registro",
  ];

  useEffect(() => {
    getOrganizations();
  }, []);

  const getCategories = (sportSlug) => {
    performRequest("POST", categoriesForTournamentsRoute, { sport_slug: sportSlug }, true, user)
      .then((response) => {
        const categories = response.data.data;
        setCategories(categories);
        setCategory(null);
        setFormData((prev) => ({
          ...prev,
          sport_slug: sportSlug,
          category_id: null,
          new_category_name: null,
          new_category_modality: null,
          modality: sportSlug === "paddle" ? "doubles" : "",
        }));
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || intl.formatMessage({ id: "Lo sentimos, ha ocurrido un error" }),
          {
            variant: "error",
          }
        );
      });
  };

  const getOrganizations = () => {
    performRequest("GET", getUserOrganizationsRoute, {}, true, user)
      .then((response) => {
        const organizations = response.data;
        if (tournament) {
          const selectedOrganization = organizations.find(
            (organization) => organization.id === tournament?.classification?.data?.organization_id
          );
          if (selectedOrganization) {
            setSelectedOrganization(selectedOrganization);
            getClassifications(selectedOrganization.id);
          }
        }
        setOrganizations(organizations);
        setOrganizationsReady(true);
      })
      .catch((error) => {
        setOrganizationsReady(true);
        console.log(error);
      });
  };

  const getClassifications = (organizationID) => {
    performRequest("POST", classificationsIndexRoute, { organization_id: organizationID }, true, user)
      .then((response) => {
        setClassifications(response.data);
        if (formData.classification_id) {
          const classification = response.data.find((c) => c.id === Number(formData.classification_id));
          if (classification) {
            setSelectedClassification(classification);
            getPunctuationSystems(organizationID);
          }
        }
      })
      .catch((error) => {
        // this.setState({ classificationsReady: true });
        console.log(error);
      });
  };

  const getPunctuationSystems = (organizationID) => {
    performRequest("POST", punctuationIndexRoute, { organization_id: organizationID }, true, user)
      .then((response) => {
        const punctuationSystems = response.data;
        setPunctuationSystems(punctuationSystems);
        if (tournament) {
          const selectedPunctuationSystem = punctuationSystems.find(
            (ps) => ps.id === Number(tournament?.punctuation_system_id)
          );
          setSelectedPunctuationSystem(selectedPunctuationSystem);
          setFormData((prev) => ({ ...prev, punctuation_system_id: selectedPunctuationSystem?.id }));
        }
      })
      .catch((error) => {
        // this.setState({ punctuationSystemsReady: true });
        console.log(error);
      });
  };

  const createTournament = (e) => {
    e.preventDefault();
    const headers = { "Content-Type": "multipart/form-data" };
    const formDataToSend = new FormData();

    setSubmitting(true);

    // Recorrer cada propiedad del objeto y agregarla a FormData
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    });

    if (tournament) {
      formDataToSend.append("tournament_id", tournament.id);
    }

    performRequest(
      "POST",
      tournament ? tournamentsUpdateRoute : tournamentsCreateRoute,
      formDataToSend,
      true,
      user,
      null,
      headers
    )
      .then((response) => {
        setSubmitting(false);
        const url = `/torneo/${tournament?.uuid_}`;
        if (window.location.pathname === url) {
          enqueueSnackbar(intl.formatMessage({ id: "Los cambios se guardaron correctamente" }), { variant: "success" });
          if (onSubmitResponse) {
            onSubmitResponse(response.data.data);
          }
        } else {
          enqueueSnackbar(intl.formatMessage({ id: "Torneo creado con éxito" }), { variant: "success" });
          history.push(`/evento/${id}/torneos`); // navegación SPA normal
        }
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(
          error?.response?.data?.message || intl.formatMessage({ id: "Lo sentimos, ha ocurrido un error" }),
          {
            variant: "error",
          }
        );
      });
  };

  const resetTournament = () => {
    performRequest("POST", restartTournamentRoute, { tournament_id: tournament.id }, true, user)
      .then((response) => {
        onSubmitResponse(false);
        enqueueSnackbar(intl.formatMessage({ id: "Torneo reiniciado con éxito" }), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || intl.formatMessage({ id: "Lo sentimos, ha ocurrido un error" }),
          {
            variant: "error",
          }
        );
      });
  };

  const confirmDeleteTournament = () => {
    const competitionUUID = tournament.competition?.data?.uuid_;
    performRequest("POST", deleteTournamentRoute, { tournament_id: tournament.id }, true, user)
      .then((response) => {
        enqueueSnackbar(intl.formatMessage({ id: "Torneo eliminado correctamente" }), { variant: "success" });
        if (competitionUUID) {
          history.push(`/evento/${competitionUUID}/torneos`);
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || intl.formatMessage({ id: "Lo sentimos, ha ocurrido un error" }),
          {
            variant: "error",
          }
        );
      });
  };

  return (
    <form onSubmit={createTournament}>
      <Stack sx={{ gap: 3 }}>
        {!tournament && <RevaText id="Nuevo torneo" variant="h2" textAlign="center" />}
        <SectionContainer title={stepTitles[0]}>
          <Stack gap={3}>
            <TextField
              required
              fullWidth
              value={formData.name}
              label={intl.formatMessage({ id: "Nombre del torneo" })}
              onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
            />
            <TextField
              rows={4}
              fullWidth
              multiline
              label={intl.formatMessage({ id: "Descripción" })}
              value={formData.description}
              onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))}
            />
            <Stack
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
                gap: 3,
              }}
            >
              <Autocomplete
                fullWidth
                value={selectedSport}
                options={sports}
                getOptionLabel={(sport) => intl.formatMessage({ id: sport.name })}
                onChange={(event, value) => {
                  setSport(value);
                  if (value) {
                    getCategories(value.slug);
                  } else {
                    setCategories([]);
                    setFormData((prev) => ({
                      ...prev,
                      sport_slug: null,
                      category_id: null,
                      new_category_name: null,
                      new_category_modality: null,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={intl.formatMessage({ id: "Seleccione el deporte del torneo" })}
                  />
                )}
              />
              <Tooltip
                followCursor
                title={!selectedSport ? intl.formatMessage({ id: "Debes seleccionar un deporte primero" }) : ""}
              >
                <CategoryAutocomplete
                  categories={categories}
                  disabled={!selectedSport}
                  selectedCategory={selectedCategory}
                  onCategorySelected={(category) => {
                    setCategory(category);
                    if (category?.id) {
                      setFormData((prev) => ({
                        ...prev,
                        category_id: category.id,
                        new_category_name: null,
                        new_category_modality: null,
                      }));
                    } else {
                      setFormData((prev) => ({
                        ...prev,
                        new_category_name: category?.name,
                        new_category_modality: category?.modality,
                        category_id: null,
                      }));
                    }
                  }}
                />
              </Tooltip>
              <DatePicker
                sx={{ width: "100%" }}
                label={intl.formatMessage({ id: "Fecha de inicio" })}
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setFormData((prev) => ({
                    ...prev,
                    start_at: dayjs(date).format("YYYY-MM-DD HH:mm:ss"),
                  }));
                }}
                slotProps={{ textField: { required: true } }}
                minDate={null}
                maxDate={formData.endDate}
              />
            </Stack>
          </Stack>
        </SectionContainer>

        <SectionContainer title={stepTitles[1]}>
          <Stack gap={3}>
            <FormControl required fullWidth>
              <InputLabel id="tournamentType">{intl.formatMessage({ id: "Tipo de torneo" })}</InputLabel>
              <Select
                label={intl.formatMessage({ id: "Tipo de torneo" })}
                labelId="tournamentType"
                value={formData.has_group_stage}
                sx={{ flexGrow: 1 }}
                onChange={(event) =>
                  setFormData((prev) => ({
                    ...prev,
                    has_group_stage: event.target.value,
                    advancing_players_per_group: null,
                    players_per_group: null,
                    perfect_bracket: false,
                    enforce_min_participants_per_group: false,
                  }))
                }
              >
                <MenuItem value={false}>{intl.formatMessage({ id: "Llave eliminatoria directa" })}</MenuItem>
                <MenuItem value={true}>
                  {intl.formatMessage({ id: "Fase de grupos + Llave eliminatoria final" })}
                </MenuItem>
              </Select>
            </FormControl>
            {formData.has_group_stage && (
              <Grid2 container spacing={3}>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                  <TextField
                    required
                    fullWidth
                    type="number"
                    label={intl.formatMessage({ id: "Participantes por grupo" })}
                    value={formData.players_per_group}
                    id="players-per-group"
                    onChange={(event) => setFormData((prev) => ({ ...prev, players_per_group: event.target.value }))}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                  <TextField
                    required
                    fullWidth
                    type="number"
                    label={intl.formatMessage({ id: "¿Cuántos avanzan por grupo?" })}
                    value={formData.advancing_players_per_group}
                    id="advancing-players-per-group"
                    onChange={(event) =>
                      setFormData((prev) => ({ ...prev, advancing_players_per_group: event.target.value }))
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <FormControl required fullWidth>
                    <InputLabel id="advance-participants-when-group-stage-is-over">
                      {intl.formatMessage({ id: "¿Cuándo avanzan los participantes a la fase final?" })}
                    </InputLabel>
                    <Select
                      label={intl.formatMessage({ id: "¿Cuándo avanzan los participantes a la fase final?" })}
                      labelId="advance-participants-when-group-stage-is-over"
                      value={formData.advance_participants_when_group_stage_is_over}
                      onChange={(event) => {
                        const advance_participants_when_group_stage_is_over = event.target.value;
                        setFormData((prev) => ({ ...prev, advance_participants_when_group_stage_is_over }));
                      }}
                    >
                      <MenuItem value={true}>
                        {intl.formatMessage({ id: "Avanzar todos juntos al finalizar la fase de grupos" })}
                      </MenuItem>
                      <MenuItem value={false}>
                        {intl.formatMessage({ id: "Avanzar participantes en cuanto terminen sus partidos" })}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid2>
              </Grid2>
            )}
            <Grid2 container spacing={3}>
              <Grid2 size={{ xs: 6, md: 4 }}>
                <FormControl required fullWidth>
                  <InputLabel id="modality">{intl.formatMessage({ id: "Modalidad de juego" })}</InputLabel>
                  <Select
                    label={intl.formatMessage({ id: "Modalidad de juego" })}
                    labelId="modality"
                    value={formData.modality}
                    onChange={(event) => {
                      const modality = event.target.value;
                      setFormData((prev) => ({
                        ...prev,
                        modality,
                        players_per_team: null,
                      }));
                    }}
                  >
                    <MenuItem value="singles">{intl.formatMessage({ id: "Individual (1 vs 1)" })}</MenuItem>
                    <MenuItem value="doubles">{intl.formatMessage({ id: "En parejas (2 vs 2)" })}</MenuItem>
                    <MenuItem value="teams">{intl.formatMessage({ id: "Por equipos" })}</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 6, md: 4 }}>
                {formData.modality === "teams" && (
                  <FormControl>
                    <OutlinedInput
                      type="number"
                      required
                      defaultValue={10}
                      value={formData.players_per_team}
                      id="players-per-team"
                      endAdornment={
                        <InputAdornment position="end">
                          {intl.formatMessage({ id: "jugadores por equipo" })}
                        </InputAdornment>
                      }
                      onChange={(event) => setFormData((prev) => ({ ...prev, players_per_team: event.target.value }))}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{ "aria-label": "players-per-team" }}
                    />
                  </FormControl>
                )}
              </Grid2>
              <Grid2 size={{ xs: 6, md: 4 }}>
                <FormControl>
                  <OutlinedInput
                    type="number"
                    value={formData.default_sets}
                    id="sets-per-match"
                    endAdornment={
                      <InputAdornment position="end">{intl.formatMessage({ id: "sets por partido" })}</InputAdornment>
                    }
                    onChange={(event) => setFormData((prev) => ({ ...prev, default_sets: event.target.value }))}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{ "aria-label": "sets" }}
                  />
                  <FormHelperText id="outlined-weight-helper-text">
                    {intl.formatMessage({ id: "Se pueden agregar más sets durante el torneo." })}
                  </FormHelperText>
                </FormControl>
              </Grid2>
            </Grid2>
            {formData.has_group_stage && (
              <Stack sx={{ mt: 1, gap: 2 }}>
                <RevaText variant="h6" id="Opciones adicionales para fase de grupos" />
                <FormControl>
                  <FormControlLabel
                    value={formData.is_home_and_away}
                    control={
                      <Checkbox
                        checked={formData.is_home_and_away}
                        onChange={(event) =>
                          setFormData((prev) => ({ ...prev, is_home_and_away: event.target.checked }))
                        }
                      />
                    }
                    label={intl.formatMessage({ id: "Ida y vuelta" })}
                  />
                  <RevaText
                    color="text.secondary"
                    id="Activa esta opción si querés que cada cruce en la fase de grupos tenga un partido de ida y otro de vuelta."
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    value={formData.enforce_min_participants_per_group}
                    control={
                      <Checkbox
                        checked={formData.enforce_min_participants_per_group}
                        onChange={(event) =>
                          setFormData((prev) => ({ ...prev, enforce_min_participants_per_group: event.target.checked }))
                        }
                      />
                    }
                    label={intl.formatMessage({ id: "Evitar grupos incompletos" })}
                  />
                  <RevaText color="text.secondary" id="No permite grupos con menos participantes de lo definido." />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    value={formData.perfect_bracket}
                    control={
                      <Checkbox
                        checked={formData.perfect_bracket}
                        onChange={(event) =>
                          setFormData((prev) => ({ ...prev, perfect_bracket: event.target.checked }))
                        }
                      />
                    }
                    label={intl.formatMessage({ id: "Llave perfecta" })}
                  />
                  <RevaText
                    color="text.secondary"
                    id="La llave final será una potencia de 2. Si faltan clasificados, avanzarán los mejores jugadores restantes."
                  />
                </FormControl>
              </Stack>
            )}
          </Stack>
        </SectionContainer>

        <SectionContainer title={stepTitles[2]}>
          <Stack gap={3}>
            <Autocomplete
              fullWidth
              title={intl.formatMessage({ id: "Organización" })}
              value={selectedOrganization}
              options={organizations}
              disabled={!organizations.length}
              getOptionLabel={(org) => org.name}
              onChange={(event, value) => {
                setSelectedOrganization(value);
                if (value) {
                  getClassifications(value.id);
                  getPunctuationSystems(value.id);
                }
                if (!value || value !== selectedOrganization) {
                  setClassifications([]);
                  setSelectedClassification(null);
                  setPunctuationSystems([]);
                  setSelectedPunctuationSystem(null);
                  setFormData((prev) => ({
                    ...prev,
                    classification_id: null,
                    punctuation_system_id: null,
                  }));
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({ id: "Organización" })}
                  placeholder={intl.formatMessage({ id: "Selecciona una organización de rankings" })}
                />
              )}
            />
            <Autocomplete
              fullWidth
              title={intl.formatMessage({ id: "Ranking" })}
              value={selectedClassification}
              options={classifications}
              disabled={!classifications.length}
              getOptionLabel={(classification) => classification.name}
              onChange={(event, value) => {
                setSelectedClassification(value);
                setFormData((prev) => ({ ...prev, classification_id: value?.id }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={intl.formatMessage({ id: "Ranking" })}
                  placeholder={intl.formatMessage({ id: "Selecciona un ranking de la organización seleccionada" })}
                />
              )}
            />
            <Autocomplete
              fullWidth
              title={intl.formatMessage({ id: "Sistema de puntuación" })}
              value={selectedPunctuationSystem}
              options={punctuationSystems}
              disabled={!punctuationSystems.length}
              getOptionLabel={(ps) => ps.name}
              onChange={(event, value) => {
                setSelectedPunctuationSystem(value);
                setFormData((prev) => ({ ...prev, punctuation_system_id: value?.id }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={intl.formatMessage({ id: "Sistema de puntuación" })}
                  placeholder={intl.formatMessage({ id: "Selecciona un sistema de puntuación para el torneo" })}
                />
              )}
            />
            <FormControl>
              <FormControlLabel
                disabled={!formData.classification_id}
                value={formData.use_ranking_system}
                control={
                  <Checkbox
                    checked={formData.use_ranking_system}
                    onChange={(event) => setFormData((prev) => ({ ...prev, use_ranking_system: event.target.checked }))}
                  />
                }
                label={intl.formatMessage({ id: "Ordenar participantes por el ranking en fases de grupos" })}
              />
              <RevaText
                color="text.secondary"
                id="Al activar esta opción, los participantes se ordenan según su posición en el ranking seleccionado. En las llaves, se ordenan según los resultados obtenidos en la fase de grupos."
              />
            </FormControl>
          </Stack>
        </SectionContainer>

        <SectionContainer title={stepTitles[3]}>
          <Stack gap={3}>
            <FormControl>
              <TextField
                required
                value={formData.signup_cap}
                sx={{ maxWidth: 320 }}
                onChange={(e) => setFormData((prev) => ({ ...prev, signup_cap: e.target.value }))}
                label={intl.formatMessage({ id: "Cantidad máxima de participantes" })}
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                value={formData.allow_self_reporting}
                control={
                  <Checkbox
                    checked={formData.allow_self_reporting}
                    onChange={(event) =>
                      setFormData((prev) => ({ ...prev, allow_self_reporting: event.target.checked }))
                    }
                  />
                }
                label={intl.formatMessage({ id: "Los jugadores pueden registrar los resultados de sus partidos" })}
              />
              <RevaText
                color="text.secondary"
                id="Si activas esta opción, los jugadores podrán reportar los resultados de sus partidos en la app. De lo contrario, solo los organizadores podrán hacerlo."
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                value={formData.requires_document_for_players}
                control={
                  <Checkbox
                    checked={formData.requires_document_for_players}
                    onChange={(event) =>
                      setFormData((prev) => ({ ...prev, requires_document_for_players: event.target.checked }))
                    }
                  />
                }
                label={intl.formatMessage({ id: "Registro con documento de identidad" })}
              />
              <RevaText
                color="text.secondary"
                id="Si activas esta opción, los jugadores deberán validar su identidad con Didit antes de inscribirse."
              />
            </FormControl>
          </Stack>
        </SectionContainer>

        <SectionContainer title={stepTitles[4]}>
          <Stack gap={3}>
            <Stack gap={3} direction="row">
              <FormControl>
                <InputLabel htmlFor="outlined-adornment-amount">
                  {intl.formatMessage({ id: "Precio de inscripción" })}
                </InputLabel>
                <OutlinedInput
                  type="number"
                  id="outlined-adornment-amount"
                  value={formData.signup_price}
                  startAdornment={<InputAdornment position="start">{formData.currency_code}</InputAdornment>}
                  label={intl.formatMessage({ id: "Precio de inscripción" })}
                  onChange={(e) => setFormData((prev) => ({ ...prev, signup_price: e.target.value }))}
                />
                <FormHelperText>
                  {intl.formatMessage({ id: "Si el torneo es gratuito, deja este campo vacío." })}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ width: 150 }}>
                <CurrencySelector
                  value={formData.currency_code}
                  onChange={(event, value) => setFormData((prev) => ({ ...prev, currency_code: value }))}
                  required
                />
              </FormControl>
            </Stack>
            <FormControl>
              <FormControlLabel
                value={formData.show_bracket}
                control={
                  <Checkbox
                    checked={formData.show_bracket}
                    onChange={(event) => setFormData((prev) => ({ ...prev, show_bracket: event.target.checked }))}
                  />
                }
                label={intl.formatMessage({ id: "Mostrar llave del torneo en la app" })}
              />
              <RevaText
                color="text.secondary"
                id="Si está activado, los jugadores podrán ver los partidos y el avance del torneo en la app."
              />
            </FormControl>
          </Stack>
        </SectionContainer>
        {tournament && (
          <Accordion sx={{ p: 2, borderRadius: 2, m: "0 !important" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <RevaText id="⚠️ Acciones peligrosas" variant="h4" color="error" />
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box>
                  <RevaText id="Reiniciar torneo" variant="subtitle1" color="text.secondary" />
                  <RevaText
                    id="Esto eliminará todos los resultados y estadísticas del torneo, pero mantendrá inscripciones y configuraciones."
                    color="text.secondary"
                  />
                  <Button variant="outlined" color="warning" sx={{ mt: 2 }} onClick={() => setRestartDialogOpen(true)}>
                    {intl.formatMessage({ id: "Reiniciar torneo" })}
                  </Button>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box>
                  <RevaText id="Eliminar torneo" variant="subtitle1" color="text.secondary" />
                  <RevaText
                    id="Esta acción eliminará el torneo por completo, incluyendo participantes, estadísticas y resultados. Es irreversible."
                    color="text.secondary"
                  />
                  <Button variant="contained" color="error" sx={{ mt: 2 }} onClick={() => setDeleteDialogOpen(true)}>
                    {intl.formatMessage({ id: "Eliminar torneo" })}
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        <AlertDialog
          hideButton
          isOpen={restartDialogOpen}
          onConfirm={() => resetTournament()}
          onCancel={() => setRestartDialogOpen(false)}
          alertBody={intl.formatMessage({ id: "¿Estás seguro de reiniciar este torneo?" })}
          confirmButtonTitle={intl.formatMessage({ id: "Reiniciar torneo" })}
          cancelButtonTitle={intl.formatMessage({ id: "Cancelar" })}
        />

        {/* Diálogo de confirmación para eliminar */}
        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
          <DialogTitle>
            <Box display="flex" alignItems="center" gap={1}>
              <WarningAmberIcon color="error" />
              <FormattedMessage id="Confirmar eliminación del torneo" />
            </Box>
          </DialogTitle>

          <DialogContent>
            <RevaText id="delete_tournament_confirmation" values={{ strong: (chunks) => <strong>{chunks}</strong> }} />
            <TextField
              fullWidth
              label={intl.formatMessage({ id: "Escribe ELIMINAR para confirmar" })}
              value={confirmationInput}
              onChange={(e) => setConfirmationInput(e.target.value)}
              sx={{ mt: 2 }}
              autoFocus
            />
          </DialogContent>

          <DialogActions>
            <Button color="text.secondary" onClick={() => setDeleteDialogOpen(false)}>
              <FormattedMessage id="Cancelar" />
            </Button>
            <Button
              color="error"
              disabled={confirmationInput !== intl.formatMessage({ id: "ELIMINAR" })}
              onClick={confirmDeleteTournament}
            >
              <FormattedMessage id="Eliminar torneo" />
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "flex-end" } }}>
          <CTAButton type="submit" loading={submitting} variant="contained" color="success">
            {intl.formatMessage({ id: tournament ? "Guardar cambios" : "Crear Torneo" })}
          </CTAButton>
        </Box>
      </Stack>
    </form>
  );
}

export default CreateTournamentWizard;
