import React, { Component } from "react";
import { Box } from "@mui/material";
import RevaText from "./RevaText";

class IconEmptyPage extends Component {
  render() {
    return (
      <Box sx={{ textAlign: "center", my: 10 }}>
        <img alt="placeholder" src="/img/no-data.svg" style={{ height: "20vh", marginBottom: 20 }} />
        <RevaText id={this.props.text} variant="h3" color="text.secondary" />
      </Box>
    );
  }
}

export default IconEmptyPage;
