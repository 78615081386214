import React, { PureComponent } from "react";
import { Grid, Avatar, CircularProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { withUserContext } from "../../hoc/withUserContext";
import { withSnackbar } from "notistack";

import { performRequest } from "../../../network/perform-request";
import { userProfileGet, participantsShowRoute } from "../../../network/api-routes";
import sports from "constants/sports";
import { forEach } from "lodash";
import RevaText from "components/common/RevaText";

const styles = (theme) => ({
  modalContainer: {
    width: "100%",
    padding: 30,
    margin: "auto",
    borderRadius: 5,
  },
  divider: {
    margin: "15px 5px 20px 5px",
  },
  icon: {
    marginLeft: 5,
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  playerSeed: {
    width: 35,
    padding: 5,
    marginRight: 10,
    fontSize: 12,
    textAlign: "center",
    borderRadius: 5,
    backgroundColor: theme.palette.action.hover,
  },
  details: {
    justifyContent: "space-between",
    padding: 5,
  },
  participantRow: {
    cursor: "default !important",
  },
  content: {
    margin: "auto",
  },
  avatar: {
    height: 30,
    width: 30,
    marginRight: 10,
  },
  avatarInGroup: {
    height: 30,
    width: 30,
  },
});

const columns = [
  { field: "sportName", headerName: "Deporte", flex: 1 },
  { field: "category", headerName: "Categoría", flex: 2 },
  { field: "played", headerName: "Jugados", flex: 1 },
  { field: "highestPosition", headerName: "Posición mas alta", flex: 2 },
  { field: "tournament", headerName: "En el torneo", flex: 2 },
];

class ProfilesModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      ready: false,
      participants: null,
      usersProfiles: [],
      showEmpty: false,
    };
  }

  componentDidMount() {
    console.log("props in ProfileModal", this.props);
    this.fetch();
  }

  fetch() {
    const { user } = this.props.userContext;
    const { participant } = this.props;

    const users = [];

    performRequest(
      "POST",
      participantsShowRoute,
      {
        participant_id: participant.id,
      },
      true,
      user
    )
      .then((response) => {
        const participant = response.data.data;
        console.log("participant is", participant);

        if (participant && participant.team && participant.team.players && participant.team.players.length > 0) {
          participant.team.players.forEach((player) => {
            if (player.playerable) users.push(player.playerable.id);
          });
          console.log("users are", users);
          const usersProfiles = [];
          if (users.length > 0) {
            let step = 0;
            users.forEach((userID, index) => {
              console.log("request", userID);
              performRequest("POST", userProfileGet, { user_id: userID }, true, user)
                .then((response) => {
                  console.log("response user profile", response, index);
                  usersProfiles.push(response.data);
                  step = step + 1;
                  if (step === users.length) {
                    this.setState({
                      usersProfiles: usersProfiles,
                      ready: true,
                    });
                    console.log("usersProfiles", usersProfiles);
                  }
                })
                .catch((error) => {
                  step = step + 1;
                  this.setState({ ready: true });
                  this.props.enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                  });
                  console.log(error);
                });
            });
          } else {
            this.setState({ showEmpty: true, ready: true });
          }
        }
      })
      .catch((error) => {
        this.setState({ ready: true });
        this.props.enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
        console.log(error);
      });
  }

  parseRows(stats) {
    const rows = [];
    forEach(stats, (category) => {
      forEach(category, (stat) => {
        const sportData = sports.find((s) => s.sport_id === parseInt(stat.sport_id, 10));
        rows.push({
          id: `${sportData.sport_id}-${stat.category.id}`,
          sportName: sportData.name,
          sportID: sportData.sport_id,
          category: stat.category.name,
          categoryID: stat.category.id,
          modality: stat.category.modality,
          highestPosition: stat.highest_position_label,
          played: stat.played_tournaments,
          tournament: stat.tournament.competition.name,
        });
      });
    });
    return rows;
  }

  renderSportsStats(stats) {
    if (!stats) return null;

    const rows = this.parseRows(stats);

    if (rows.length > 0) {
      return (
        <div style={{ width: "100%", cursor: "pointer" }}>
          <DataGrid
            rows={rows}
            hideFooterSelectedRowCount
            columns={columns}
            disableColumnMenu
            hideFooter
            autoHeight
            autoPageSize
          />
        </div>
      );
    }
  }

  renderUser(userData) {
    if (!userData) return null;

    const { classes } = this.props;

    const { stats_by_sport, user } = userData;
    console.log("render user", userData);
    return (
      <Grid container key={user.data.id} style={{ marginTop: 30 }}>
        <Grid container item>
          <Grid container direction="row" alignItems="center">
            <Avatar className={classes.largeAvatar} key={user.id} src={user.full_profile_pic_url} />
            <RevaText variant="h2" style={{ marginLeft: 5 }}>
              {user.data.full_name}
            </RevaText>
          </Grid>
        </Grid>

        <Grid container item style={{ marginTop: 10 }}>
          {this.renderSportsStats(stats_by_sport)}
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    const { ready, usersProfiles, showEmpty } = this.state;

    if (!ready) {
      return (
        <Grid container className={classes.modalContainer} justifyContent="center">
          <CircularProgress size={16}></CircularProgress>
        </Grid>
      );
    }

    if (showEmpty) {
      return (
        <>
          <Grid className={classes.modalContainer}>
            <Grid>
              <RevaText style={{ marginBottom: 20 }} variant="h1">
                No hay datos de estos jugadores.
              </RevaText>

              <Grid container item direction="row">
                <RevaText style={{ marginBottom: 20 }} variant="body1">
                  Puedes asignar el perfil de Reva a cada jugador
                </RevaText>

                <RevaText
                  variant="body1"
                  onClick={() => this.props.editParticipant()}
                  style={{ color: green[300], marginLeft: 3 }}
                >
                  {"editando el participante"}
                </RevaText>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid className={classes.modalContainer}>
          <Grid>
            <RevaText style={{ marginBottom: 20 }} variant="h1">
              Perfiles
            </RevaText>
          </Grid>

          {usersProfiles.map((profile) => {
            return this.renderUser(profile);
          })}
        </Grid>
      </>
    );
  }
}
export default withSnackbar(withUserContext(withStyles(styles)(ProfilesModal)));
