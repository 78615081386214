import React, { Component } from "react";
import performRequest from "../../../network/perform-request";
import { competitionsCalendarEventsRoute, competitionsShowRoute } from "../../../network/api-routes";
import { Box, CircularProgress, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGrid from "@fullcalendar/resource-timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import { Dialog } from "@mui/material";
import EditMatchModal from "../Tournament/EditMatchModal";
import { withSnackbar } from "notistack";
import { Chip } from "@mui/material";
import { injectIntl } from "react-intl";
import RevaText from "components/common/RevaText";

const styles = (theme) => ({
  legendsContainer: {
    marginTop: theme.spacing(1),
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
});

class CompetitionCalendar extends Component {
  constructor(props) {
    super();

    this.state = {
      id: props.match.params.id,
      ready: false,
      resources: [],
      selectedMatch: null,
    };

    this.fetchEvents = this.fetchEvents.bind(this);
  }

  componentDidMount() {
    this.fetch(this.props.userContext.user);
  }

  fetch(user) {
    performRequest(
      "POST",
      competitionsShowRoute,
      { competition_uuid: this.state.id, include_calendar_resources: true },
      true,
      user
    )
      .then((response) => {
        this.setState({
          competition: response.data.data,
          resources: response.data.meta.calendar_resources,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchEvents(info, successCallback, failureCallback) {
    performRequest(
      "POST",
      competitionsCalendarEventsRoute,
      {
        competition_id: this.state.competition.id,
        start: info.startStr,
        end: info.endStr,
      },
      true,
      this.props.userContext.user
    )
      .then((response) => {
        return successCallback(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onMatchUpdated(response) {
    const { intl } = this.props;
    this.props.enqueueSnackbar(intl.formatMessage({ id: "Partido actualizado" }), {
      variant: "success",
    });
    this.setState(
      {
        selectedMatch: null,
      },
      () => {
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.refetchEvents();
      }
    );
  }

  renderLegends() {
    const { classes } = this.props;
    const tournaments = this.state.competition.tournaments.data;
    console.log(tournaments);

    return (
      <div className={classes.legendsContainer}>
        {tournaments.map((tournament, index) => (
          <Chip
            key={index}
            size="small"
            label={tournament.name}
            style={{ backgroundColor: this.legendColor(index) }}
          ></Chip>
        ))}
      </div>
    );
  }

  legendColor(i) {
    const colors = [
      "#4CAF50",
      "#FF5722",
      "#2196F3",
      "#9C27B0",
      "#3F51B5",
      "#8BC34A",
      "#E91E63",
      "#00BCD4",
      "#795548",
      "#009688",
    ];
    const size = colors.length;
    return colors[i % size];
  }

  calendarRef = React.createRef();

  render() {
    const { intl } = this.props;
    const { selectedMatch } = this.state;
    if (!this.state.ready) {
      return (
        <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
          <CircularProgress size={24}></CircularProgress>
        </Grid>
      );
    }

    if (this.state.resources.length === 0) {
      return (
        <Grid item container justifyContent="center" style={{ marginTop: 30, textAlign: "center" }}>
          <RevaText id="Aún no hay partidos programados. Asigna una fecha y un lugar a los partidos de tus torneos para verlos aquí." />
        </Grid>
      );
    }

    return (
      <>
        <Box>
          <div style={{ height: "85vh" }}>
            <FullCalendar
              ref={this.calendarRef}
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              plugins={[resourceTimeGrid, dayGridPlugin, scrollGridPlugin]}
              initialView="dayGridMonth"
              resources={this.state.resources}
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
              }}
              resourceOrder={"order"}
              allDaySlot={false}
              locale={intl.locale}
              headerToolbar={{
                left: "title",
                center: "",
                right: "resourceTimeGridDay,dayGridMonth today prev,next",
              }}
              events={this.fetchEvents}
              buttonText={{
                today: intl.formatMessage({ id: "Hoy" }),
                month: intl.formatMessage({ id: "Mes" }),
                day: intl.formatMessage({ id: "Día" }),
              }}
              height="100%"
              navLinks={true}
              expandRows={true}
              aspectRatio={0.1}
              slotDuration={"00:60:00"}
              dayMinWidth={200}
              dayMaxEventRows={true}
              eventClick={(info) => {
                this.setState({
                  selectedMatch: info.event.extendedProps.match,
                });
              }}
              loading={(loading) =>
                this.setState({
                  loading,
                })
              }
            />
            {this.state.loading && (
              <div
                style={{
                  position: "absolute",
                  right: "53px",
                  top: "186px",
                  zIndex: 10000,
                }}
              >
                <CircularProgress size={48}></CircularProgress>
              </div>
            )}
          </div>

          {this.renderLegends()}
        </Box>
        <Dialog
          open={Boolean(selectedMatch)}
          onBackdropClick={() => this.setState({ selectedMatch: null })}
          disableAutoFocus
          fullWidth={false}
          maxWidth="md"
        >
          {selectedMatch && (
            <EditMatchModal
              matchId={selectedMatch.data.id}
              tabName={"details"}
              canEdit={true}
              isBracketStage={selectedMatch.data.tournament.data.state === "underway"}
              showScoreTab={true}
              onSubmitScore={() => this.onMatchUpdated()}
            />
          )}
        </Dialog>
      </>
    );
  }
}

export default withSnackbar(withStyles(styles)(injectIntl(CompetitionCalendar)));
