import React, { Component } from "react";
import performRequest from "../../../network/perform-request";
import {
  competitionsUsersRoute,
  competitionsUsersInviteRoute,
  competitionUsersCancelInviteRoute,
  competitionUsersDeleteRoute,
} from "../../../network/api-routes";
import Divider from "@mui/material/Divider";
import {
  Button,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Avatar,
  Stack,
} from "@mui/material";
import AlertDialog from "../../common/AlertDialog";
import RevaText from "components/common/RevaText";
import { injectIntl } from "react-intl";

class CompetitionAdmins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      ready: false,
      users: [],
      selectedIndex: 0,
      openModal: false,
      email: "",
      userInvites: [],
      cancelInviteOpen: false,
      selectedUserInvite: null,
      deleteUserOpen: false,
      selectedUser: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.submitInvite = this.submitInvite.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancelInvite = this.cancelInvite.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    this.fetch(this.props.userContext.user);
  }

  fetch(user) {
    performRequest("POST", competitionsUsersRoute, { competition_uuid: this.state.id }, true, user)
      .then((response) => {
        this.setState({
          users: response.data.data,
          userInvites: response.data.meta.pending_invites,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reset() {
    this.setState({
      email: "",
      selectedUserInvite: null,
      selectedUser: null,
    });
  }

  submitInvite() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionsUsersInviteRoute,
        {
          competition_uuid: this.state.id,
          email: this.state.email,
        },
        true,
        user
      )
        .then((response) => {
          this.reset();

          this.fetch();
        })
        .catch((error) => {
          this.reset();
          this.fetch();
        });
    });
  }

  cancelInvite() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionUsersCancelInviteRoute,
        {
          user_invite_id: this.state.selectedUserInvite.id,
        },
        true,
        user
      )
        .then((response) => {
          this.setState(
            {
              selectedUserInvite: null,
              ready: false,
            },
            () => {
              this.fetch();
            }
          );
        })
        .catch((error) => {
          this.fetch();
        });
    });
  }

  handleClose() {
    this.setState({ openModal: false });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderAddDialog() {
    const { intl } = this.props;
    return (
      <div>
        <Dialog open={this.state.openModal} onClose={this.handleClose}>
          <DialogTitle>{intl.formatMessage({ id: "Invitar a un administrador" })}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {intl.formatMessage({
                id: "Ingresa el correo de un administrador del torneo para invitarlo a ayudarte a gestionarlo.",
              })}
            </DialogContentText>
            <TextField
              autoFocus
              size="small"
              id="name"
              label="Email"
              type="email"
              fullWidth
              value={this.state.email}
              sx={{ mt: 2 }}
              onChange={this.handleChange("email")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} sx={{ color: "text.secondary" }}>
              {intl.formatMessage({ id: "Cancelar" })}
            </Button>
            <Button onClick={this.submitInvite}>{intl.formatMessage({ id: "Enviar invitación" })}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  renderConfirmCancelInviteDialog() {
    return (
      <AlertDialog
        hideButton
        isOpen={this.state.cancelInviteOpen}
        onConfirm={() => this.setState({ cancelInviteOpen: false }, this.cancelInvite)}
        onCancel={() => this.setState({ cancelInviteOpen: false })}
        alertBody="¿Estás seguro que quieres cancelar la invitación?"
        confirmButtonTitle="Si, estoy seguro"
        cancelButtonTitle="Volver atrás"
      />
    );
  }

  deleteUser() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionUsersDeleteRoute,
        {
          competition_uuid: this.state.id,
          user_id: this.state.selectedUser.id,
        },
        true,
        user
      )
        .then((response) => {
          this.setState(
            {
              selectedUserInvite: null,
              ready: false,
            },
            () => {
              this.fetch();
            }
          );
        })
        .catch((error) => {
          this.fetch();
        });
    });
  }
  renderConfirmDeleteUserDialog() {
    return (
      <AlertDialog
        hideButton
        isOpen={this.state.deleteUserOpen}
        onConfirm={() => this.setState({ deleteUserOpen: false }, this.deleteUser)}
        onCancel={() => this.setState({ deleteUserOpen: false })}
        alertBody="¿Estás seguro que quieres remover a este usuario?"
        confirmButtonTitle="Si, estoy seguro"
        cancelButtonTitle="Volver atrás"
      />
    );
  }

  render() {
    const { intl } = this.props;

    if (!this.state.ready) {
      return (
        <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
          <CircularProgress size={24}></CircularProgress>
        </Grid>
      );
    }

    return (
      <Stack spacing={4}>
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <RevaText id="Administradores" variant="h2" />
          <Button
            variant="contained"
            onClick={() => {
              this.setState({ openModal: true });
            }}
          >
            {intl.formatMessage({ id: "Agregar" })}
          </Button>
        </Stack>

        {this.state.users.map((user) => (
          <Stack key={user.id} direction="row">
            <Avatar src={user.full_profile_pic_url} sx={{ mr: 2 }} alt={user.full_name} />
            <Stack sx={{ flexGrow: 1 }}>
              <RevaText variant="h4">{user.full_name}</RevaText>
              <RevaText>{user.email || "-"}</RevaText>
            </Stack>
            {user.editable && (
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({ selectedUser: user, deleteUserOpen: true });
                }}
              >
                {intl.formatMessage({ id: "Remover" })}
              </Button>
            )}
          </Stack>
        ))}

        {this.state.userInvites.length > 0 && (
          <>
            <Divider />
            <RevaText id="Invitaciones pendientes" variant="h4" />
            <Stack spacing={2}>
              {this.state.userInvites.map((userInvite) => (
                <Stack direction="row" key={userInvite.id}>
                  <RevaText sx={{ flexGrow: 1 }}>{userInvite.email}</RevaText>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      this.setState({ selectedUserInvite: userInvite, cancelInviteOpen: true });
                    }}
                  >
                    {intl.formatMessage({ id: "Cancelar" })}
                  </Button>
                </Stack>
              ))}
            </Stack>
          </>
        )}
        {this.renderAddDialog()}
        {this.renderConfirmCancelInviteDialog()}
        {this.renderConfirmDeleteUserDialog()}
      </Stack>
    );
  }
}

export default injectIntl(CompetitionAdmins);
