import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, CircularProgress, FormControl, InputLabel, Select, MenuItem, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { participantsMoveToTournament, tournamentsIndexRoute } from "network/api-routes";
import performRequest from "network/perform-request";
import { useSnackbar } from "notistack";
import RevaText from "components/common/RevaText";
import UserContext from "context/UserContext";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: "100%",
    padding: 30,
    margin: "auto",
    borderRadius: 5,
    minHeight: 500,
  },
}));

const ChangeTournamentModal = ({ competitionId, participant, onSave }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const [tournaments, setTournaments] = useState([]);
  const [ready, setReady] = useState(false);
  const [selectedTournamentId, setSelectedTournamentId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  useEffect(() => {
    performRequest(
      "POST",
      tournamentsIndexRoute,
      {
        state: "pending",
        competition_id: competitionId,
      },
      true,
      user
    )
      .then((response) => {
        setTournaments(response.data.data);
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [competitionId, user]);

  const handleSave = () => {
    setReady(false);
    performRequest(
      "POST",
      participantsMoveToTournament,
      {
        participant_id: participant.id,
        tournament_id: selectedTournamentId,
        category_id: selectedCategoryId,
      },
      true,
      user
    )
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: "success" });
        onSave();
      })
      .catch((error) => {
        console.error(error);
        setReady(true);
        enqueueSnackbar(error.response?.data?.message || "Error", {
          variant: "error",
        });
      });
  };

  const selectedTournament = tournaments.find((t) => t.id === selectedTournamentId);

  if (!ready) {
    return (
      <Grid container justifyContent="center" alignItems="center" className={classes.modalContainer}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Stack sx={{ p: 4, gap: 3 }}>
      <RevaText variant="h4" id="Mover a otro torneo" />

      <FormControl variant="outlined" fullWidth>
        <InputLabel id="tournament-select-label">{intl.formatMessage({ id: "Torneo" })}</InputLabel>
        <Select
          labelId="tournament-select-label"
          id="tournament-select"
          value={selectedTournamentId}
          onChange={(e) => {
            setSelectedTournamentId(e.target.value);
            setSelectedCategoryId(""); // Resetear categoría si es necesario
          }}
          label={intl.formatMessage({ id: "Torneo" })}
        >
          {tournaments.map((t) => (
            <MenuItem key={t.id} value={t.id}>
              {t.competition.name} - {t.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        disabled={!selectedTournament}
        variant="contained"
        size="large"
        color="primary"
        onClick={handleSave}
        sx={{ alignSelf: "flex-end" }}
      >
        {intl.formatMessage({ id: "Cambiar de torneo" })}
      </Button>
    </Stack>
  );
};

export default ChangeTournamentModal;
