import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Grid, Button, TextField, FormControl, CircularProgress, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import { find } from "lodash";
import { withSnackbar } from "notistack";
import performRequest from "../../../network/perform-request";
import { punctuationCreateRoute, punctuationUpdateRoute, getUserOrganizationsRoute } from "network/api-routes";

import OrganizationAutocomplete from "./OrganizationAutocomplete";
import PunctuationSystemTable from "./PunctuationSystemTable";

import { red } from "@mui/material/colors";
import RevaText from "components/common/RevaText";

const styles = (theme) => ({
  container: {
    margin: theme.spacing(8),
  },
  title: {
    fontWeight: 500,
    fontSize: 48,
    color: theme.palette.text.secondary,
  },
  sectionContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    marginBottom: theme.spacing(8),
    boxShadow: "none",
  },
  sectionTitle: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 14,
  },
  sectionTitleContainer: {
    backgroundColor: theme.palette.action.hover,
    height: 46,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    "&$expanded": {
      maxHeight: 46,
    },
  },
  sectionBodyContainer: {
    padding: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "1rem",
  },
  input: {
    width: "100%",
    marginLeft: 5,
  },
  formRow: {
    marginBottom: theme.spacing(4),
  },
  createButton: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
  },
});

const Section = (props) => {
  const { classes } = props;

  return (
    <Paper className={classes.sectionContainer}>
      <Grid container>
        <Grid item xs={12} className={classes.sectionTitleContainer}>
          <RevaText variant="h1" className={classes.sectionTitle}>
            {props.title}
          </RevaText>
        </Grid>
        <Grid item xs={12} className={classes.sectionBodyContainer}>
          {props.children}
        </Grid>
      </Grid>
    </Paper>
  );
};

const FormRow = (props) => {
  const { classes } = props;

  return (
    <Grid container className={classes.formRow} alignItems="center">
      <Grid item xs={5}>
        <RevaText variant="h3" className={classes.label}>
          {props.label}
        </RevaText>
      </Grid>
      <Grid item xs={7}>
        {props.children}
      </Grid>
    </Grid>
  );
};

const PunctuationForm = (props) => {
  const { classes, userContext, punctuationSystem } = props;
  const { user } = userContext;

  const [submitting, setSubmitting] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsReady, setOrganizationsReady] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [name, setName] = useState(punctuationSystem?.name || "");
  const [description, setDescription] = useState(punctuationSystem?.description || "");

  const [table, setTable] = useState(null);

  const fetchOrganizations = useCallback(() => {
    setOrganizationsReady(false);
    performRequest("GET", getUserOrganizationsRoute, {}, true, user)
      .then((response) => {
        const organizations = response.data;
        setOrganizations(organizations);
        const org = find(organizations, {
          id: punctuationSystem?.organization_id,
        });
        if (org) {
          setSelectedOrganization(org);
        }
        setOrganizationsReady(true);
      })
      .catch((error) => console.error(error));
  }, [user, punctuationSystem]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  const renderOrganizationAutocomplete = useMemo(() => {
    if (!organizationsReady) {
      return <CircularProgress size={24} />;
    }

    return (
      <>
        <OrganizationAutocomplete
          organizations={organizations}
          defaultValue={selectedOrganization}
          onOrganizationSelected={(category) => {
            setSelectedOrganization(category);
          }}
        />
        {!selectedOrganization && (
          <RevaText variant="caption" style={{ color: red[500] }}>
            Elige una organización para el ranking (requerido)
          </RevaText>
        )}
      </>
    );
  }, [organizations, organizationsReady, selectedOrganization]);

  const handleChange = useCallback(
    (name) => (event) => {
      const value = event.target.value;
      switch (name) {
        case "description":
          if (value.length <= 500) {
            setDescription(value);
          }
          break;
        case "name":
          if (value.length <= 100) {
            setName(value);
          }
          break;
        default:
          break;
      }
    },
    []
  );

  const handleTableChange = useCallback((tab) => {
    setTable(tab);
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setSubmitting(true);

      let formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("organization_id", selectedOrganization.id);

      if (punctuationSystem) {
        formData.append("punctuation_system_id", punctuationSystem.id);
      }

      if (table) {
        const pointsTable = JSON.stringify({ paddle: { ...table } });
        formData.append("points_table", pointsTable);
      }

      performRequest(
        "POST",
        punctuationSystem ? punctuationUpdateRoute : punctuationCreateRoute,
        formData,
        true,
        user,
        null,
        { "Content-Type": "multipart/form-data" }
      )
        .then((response) => {
          const newPunctuationSystem = response.data;
          setSubmitting(false);
          if (punctuationSystem) {
            props.enqueueSnackbar("Sistema de puntuación actualizado", {
              variant: "success",
            });
            props.history.goBack();
          } else {
            props.onSubmitResponse(newPunctuationSystem);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          props.enqueueSnackbar(error?.response?.data?.message || "Ha ocurrido un error. Favor revisa tus datos", {
            variant: "error",
          });
          console.error(error);
        });
    },
    [name, description, selectedOrganization, punctuationSystem, table, user, props]
  );

  return (
    <Grid container xs={12}>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <Section title="Información básica" {...props}>
              <FormRow label="Nombre del sistema" {...props}>
                <FormControl variant="standard" margin="normal" required fullWidth>
                  <TextField
                    variant="standard"
                    value={name}
                    onChange={handleChange("name")}
                    className={classes.input}
                    required
                  />
                </FormControl>
              </FormRow>
              <FormRow label="Descripción" {...props}>
                <TextField
                  variant="standard"
                  multiline
                  maxRows={4}
                  value={description || ""}
                  onChange={handleChange("description")}
                  className={classes.input}
                  placeholder="Aquí puedes dar más detalles sobre este sistema de puntuación"
                />
              </FormRow>

              {organizations && (
                <FormRow label="Organización" {...props}>
                  {renderOrganizationAutocomplete}
                </FormRow>
              )}
            </Section>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Section title="Criterios de puntuación" {...props}>
              <PunctuationSystemTable
                table={punctuationSystem?.points_table?.paddle}
                onChange={(punctuationTable) => handleTableChange(punctuationTable)}
              />
            </Section>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            disabled={submitting || !selectedOrganization}
            type="submit"
            className={`cta ${classes.createButton}`}
          >
            {submitting && <CircularProgress size={24} />}
            {punctuationSystem ? "Guardar cambios" : "Guardar y continuar"}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default withSnackbar(withStyles(styles)(PunctuationForm));
