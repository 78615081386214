const modalities = [
  {
    name: "Masculina",
    slug: "male",
  },
  {
    name: "Femenina",
    slug: "female",
  },
  { name: "Mixta", slug: "mix" },
];

export default modalities;
