import { createTheme } from "@mui/material/styles";
import colors from "./colors";
import typography from "./typography";
import components from "./components";

const theme = createTheme({
  palette: {
    primary: colors.primary,
    secondary: colors.secondary,
    background: colors.background,
    text: colors.text,
    success: { main: colors.success },
    error: { main: colors.error },
    warning: { main: colors.warning },
    info: { main: colors.info },
    mode: "dark",
  },
  typography,
  components,
});

export default theme;
