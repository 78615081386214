import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Tabs,
  Tab,
  Button,
  Grid,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  InputAdornment,
  Switch,
  Avatar,
  Stack,
  Grid2,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import TimeIcon from "@mui/icons-material/AccessTime";
import "react-datepicker/dist/react-datepicker.css";

import RevaLogo from "../../../assets/iso-white.svg";
import { matchesUpdateRoute, matchesShowRoute } from "../../../network/api-routes";
import performRequest from "../../../network/perform-request";
import { withUserContext } from "../../hoc/withUserContext";
import PlaceIcon from "@mui/icons-material/Place";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import { debounce } from "lodash";
import MatchScoreDetails from "../../common/MatchScoreDetails";
import RevaText from "components/common/RevaText";
import { injectIntl } from "react-intl";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CTAButton from "components/common/CTAButton";
const CLOSED = "closed";

const styles = (theme) => ({
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  scoreTableContainer: {
    width: "100%",
    overflow: "auto",
  },
  score: {
    width: 70,
  },
  scoreInput: {
    fontSize: 16,
  },
  scoreButton: {
    margin: "auto",
    fontSize: 18,
    fontWeight: 700,
    padding: "15px 30px",
    color: "#FFFFFF",
  },
  winnerContainer: {
    textAlign: "center",
    fontWeight: 700,
    margin: "30px 0",
  },
  pickWinnerButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  selectedWinnerButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
  },
  submitScoreContainer: {
    backgroundColor: theme.palette.action.selected,
    borderRadius: 5,
    padding: 20,
    marginTop: 16,
  },
  playerCard: {
    flex: 1,
    textAlign: "center",
    backgroundColor: theme.palette.action.selected,
  },
  avatar: {
    flex: 1,
    padding: 15,
    width: "inherit",
    objectFit: "contain",
  },
  profilePic: {
    width: "inherit",
    borderRadius: 50,
  },
  detailsTable: {
    margin: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.divider,
  },
  scoreDetails: {
    padding: "15px 24px",
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderColor: "inherit",
  },
});

const MATCH_IN_PROGRESS = 0;
const TIE = -1;

const TABS = {
  details: 0,
  score: 1,
  scoreDetails: 2,
};

class EditMatchModal extends Component {
  constructor(props) {
    super(props);

    let tabIndex = 0;
    if (this.props.tabName) {
      tabIndex = TABS[this.props.tabName];
    }

    this.state = {
      tabIndex,
      ready: false,
      startDate: null,
      dateToShow: null,
      showScoreDetailsTab: false,
      hasLive: false,
      channel_name: "",
      liveNow: false,
    };

    this.handleSpaceChange = this.handleSpaceChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.debouncedUpdateScore = debounce(this.updateScore, 250, {
      maxWait: 1000,
    });
    this.debouncedUpdateScore = this.debouncedUpdateScore.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    const { user } = this.props;
    performRequest(
      "POST",
      matchesShowRoute,
      {
        match_id: this.props.matchId,
      },
      true,
      user
    )
      .then((result) => {
        const matchInfo = result.data.data;
        const scoresBySet = matchInfo.scores_csv && matchInfo.scores_csv.split(",");
        const tournament = matchInfo.tournament.data;
        let playerScores = [];

        if (scoresBySet) {
          scoresBySet.forEach((set) => {
            const score = set.split("-").map(Number);
            playerScores.push([score[0], score[1]]);
          });
        }

        const dateToShow = matchInfo.scheduled_time && dayjs(matchInfo.scheduled_time);
        let defaultScores = [[0, 0]];
        if (tournament.default_sets > 1) {
          for (let i = 0; i < tournament.default_sets - 1; i++) {
            defaultScores.push([0, 0]);
          }
        }

        let winner = matchInfo.winner_id;
        if (matchInfo.in_progress) {
          winner = MATCH_IN_PROGRESS;
        }

        this.setState({
          matchInfo: matchInfo,
          playerScores: playerScores.length ? playerScores : defaultScores,
          scoresBySet: scoresBySet,
          winner,
          dateToShow,
          startDate: matchInfo.scheduled_time,
          spaces: result.data.meta.spaces.data,
          showScoreDetailsTab: result.data.meta.score_details_allowed,
          selectedSpaceId: matchInfo.space_id || "",
          walkover: matchInfo.is_walkover,
          duration: matchInfo.match_duration,
          ready: true,
          tournament,
          hasLive: matchInfo.has_live,
          channel_name: matchInfo.channel_name,
          liveNow: matchInfo.live_now,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateScore(callback) {
    const scoresCSV = [...this.state.playerScores]; // We clone it since we need to modify the array
    const { user } = this.props;
    const { matchInfo } = this.state;

    if (scoresCSV && scoresCSV.length) {
      for (let i = 0; i < scoresCSV.length; i++) {
        if (typeof scoresCSV[i].join === "function") {
          scoresCSV.splice(i, 1, scoresCSV[i].join("-"));
        }
      }
    }
    performRequest(
      "POST",
      matchesUpdateRoute,
      {
        match_id: matchInfo.id,
        scores_csv: scoresCSV ? scoresCSV.join(",") : null,
        live_now: this.state.liveNow,
      },
      true,
      user
    )
      .then((result) => {
        if (callback) {
          callback(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateMatch() {
    const { user, onSubmitScore } = this.props;
    const { winner, walkover, matchInfo } = this.state;
    const scoresCSV = this.state.playerScores;

    console.log(scoresCSV);
    if (scoresCSV && scoresCSV.length) {
      for (let i = 0; i < scoresCSV.length; i++) {
        if (typeof scoresCSV[i].join === "function") {
          scoresCSV.splice(i, 1, scoresCSV[i].join("-"));
        }
      }
    }
    const data = {
      match_id: matchInfo.id,
      winner_id: winner === MATCH_IN_PROGRESS || winner === TIE ? null : winner,
      scores_csv: scoresCSV ? scoresCSV.join(",") : null,
      is_tie: winner === TIE,
      in_progress: winner === MATCH_IN_PROGRESS,
      is_walkover: walkover,
    };
    console.log("data to post", data);
    performRequest("POST", matchesUpdateRoute, data, true, user)
      .then((result) => {
        onSubmitScore(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  update(callback) {
    const { user } = this.props;
    const { matchInfo } = this.state;

    const data = {
      match_id: matchInfo.id,
      scheduled_time: this.state.startDate,
      space_id: this.state.selectedSpaceId,
      duration: this.state.duration && typeof this.state.duration === "number" ? this.state.duration : 60,
      has_live: this.state.hasLive,
      channel_name: this.state.hasLive ? this.state.channel_name : null,
    };
    console.log("data to post", data);
    performRequest("POST", matchesUpdateRoute, data, true, user)
      .then((result) => {
        if (callback) {
          callback(result.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (setIndex, playerIndex) => (event) => {
    const { matchInfo } = this.state;
    const participants = [matchInfo.player1.data, matchInfo.player2.data];
    const newPlayerScores = this.state.playerScores;
    let winnerId = this.state.winner;
    let playerOneSets = 0;
    let playerTwoSets = 0;

    if (setIndex !== null && playerIndex !== null) {
      newPlayerScores[setIndex][playerIndex] = Number(event.target.value);
    }

    newPlayerScores.forEach((set) => {
      if (set[0] > set[1]) {
        playerOneSets++;
      }
      if (set[0] < set[1]) {
        playerTwoSets++;
      }
    });

    if (playerOneSets > playerTwoSets) {
      winnerId = participants[0].id;
    }
    if (playerOneSets < playerTwoSets) {
      winnerId = participants[1].id;
    }
    if (playerOneSets === playerTwoSets) {
      winnerId = -1;
    }

    this.setState(
      {
        playerScores: newPlayerScores,
        winner: winnerId,
      },
      () => {
        this.debouncedUpdateScore();
      }
    );
  };

  handleSelectChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }

  handleInputChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  }

  handleSwitchChange(event) {
    const targetName = event.target.name;

    this.setState(
      {
        ...this.state,
        [event.target.name]: !this.state[event.target.name],
      },
      () => {
        if (targetName === "liveNow") {
          this.updateScore();
        }
      }
    );
  }

  onSetChange(action) {
    const scores = this.state.playerScores;

    if (action === "add") {
      scores.push([0, 0]);
      this.handleChange();
    } else {
      scores.pop();
    }

    this.setState({
      playerScores: scores,
    });
  }

  handleDateChange(date) {
    console.log(date);
    this.setState({
      startDate: date || null,
    });
  }

  handleSpaceChange(event) {
    this.setState({
      selectedSpaceId: event.target.value,
    });
  }

  handleTextInputChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderTeamScoreDetails(participant) {
    const { matchInfo } = this.state;
    const team = participant.team.data;
    const teamPlayers = team.players.data;
    const standings = matchInfo.score_details["standingsByPlayerId"];
    const goalers = teamPlayers.filter((teamPlayer) => standings[teamPlayer.id]?.goals > 0);
    const warnedPlayers = teamPlayers.filter((teamPlayer) => standings[teamPlayer.id]?.warnings > 0);
    const expelledPlayers = teamPlayers.filter((teamPlayer) => standings[teamPlayer.id]?.bans > 0);

    return (
      <Grid item xs={4} style={{ textAlign: "center" }}>
        {goalers.length > 0 && (
          <Grid>
            <RevaText>
              <span role="img" aria-label="goals">
                ⚽
              </span>
              {" Goleadores "}
              <span role="img" aria-label="goals">
                ⚽
              </span>
            </RevaText>
            <RevaText>{goalers.map((player) => `${player.name}(${standings[player.id].goals})`).join(", ")}</RevaText>
          </Grid>
        )}
        {warnedPlayers.length > 0 && (
          <Grid justifyContent="center">
            <RevaText>🟨 Amonestados 🟨</RevaText>
            <RevaText>{warnedPlayers.map((player) => player.name).join(", ")}</RevaText>
          </Grid>
        )}
        {expelledPlayers.length > 0 && (
          <Grid justifyContent="center">
            <RevaText>🟥 Expulsados 🟥</RevaText>
            <RevaText>{expelledPlayers.map((player) => player.name).join(", ")}</RevaText>
          </Grid>
        )}
      </Grid>
    );
  }

  renderDetails() {
    const { classes, onSubmitScore, canEdit, intl } = this.props;
    const { scoresBySet, matchInfo, tournament } = this.state;

    if (!this.state.ready) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      );
    }

    const participants = [matchInfo.player1, matchInfo.player2];
    const participant1Pic = participants[0] && participants[0].data.full_profile_pic_url;
    const participant2Pic = participants[1] && participants[1].data.full_profile_pic_url;

    const tournamentStarted = tournament.state !== "pending";

    return (
      <Stack>
        <Stack sx={{ mt: 4, flexDirection: { xs: "column", md: "row" } }}>
          <Card
            className={classes.playerCard}
            sx={{ display: { xs: "flex", md: "block" }, alignItems: "center", py: 2 }}
          >
            <Avatar
              variant="rounded"
              src={participant1Pic || RevaLogo}
              alt={participants[0]?.data.display_name}
              sx={{ height: { xs: 50, md: 70 }, width: { xs: 50, md: 70 }, mx: { xs: 2, md: "auto" } }}
            />
            <RevaText variant="h6" sx={{ mt: { xs: 0, md: 2 } }}>
              {(participants[0] && participants[0].data.name) || "-"}
            </RevaText>
          </Card>
          <RevaText variant="h5" sx={{ margin: { xs: "10px auto", md: "auto 10px" } }}>
            vs
          </RevaText>
          <Card
            className={classes.playerCard}
            sx={{
              py: 2,
              display: { xs: "flex", md: "block" },
              flexDirection: { xs: "row-reverse", md: "column" },
              alignItems: "center",
            }}
          >
            <Avatar
              variant="rounded"
              src={participant2Pic || RevaLogo}
              alt={participants[1]?.data.display_name}
              sx={{ height: { xs: 50, md: 70 }, width: { xs: 50, md: 70 }, mx: { xs: 2, md: "auto" } }}
            />
            <RevaText variant="h6" sx={{ mt: { xs: 0, md: 2 } }}>
              {(participants[1] && participants[1].data.name) || "-"}
            </RevaText>
          </Card>
          <Card />
        </Stack>

        {scoresBySet && (
          <Stack direction="row" sx={{ mt: 3, gap: 2 }}>
            {this.renderTeamScoreDetails(participants[0].data)}
            <Table className={classes.detailsTable}>
              <TableBody>
                {participants.map((player, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row" style={{ minWidth: 200 }}>
                      {(player && player.data.name) || "-"}
                    </TableCell>
                    {scoresBySet.map((set, setId) => (
                      <TableCell key={`Set ${setId + 1}`} component="th" scope="row" className={classes.scoreDetails}>
                        {set.split("-")[i]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {this.renderTeamScoreDetails(participants[1].data)}
          </Stack>
        )}

        {canEdit && (
          <div className={classes.submitScoreContainer}>
            {tournamentStarted ? (
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <DateTimePicker
                    label={intl.formatMessage({ id: "Fecha y hora" })}
                    value={this.state.dateToShow}
                    onChange={(date) => {
                      const startDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
                      this.setState({ dateToShow: date, startDate });
                    }}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </Grid2>

                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TextField
                    value={this.state.duration}
                    label={intl.formatMessage({ id: "Duración" })}
                    type={"number"}
                    onChange={this.handleTextInputChange("duration")}
                    InputProps={{
                      startAdornment: <TimeIcon sx={{ fontSize: 20, mr: 2 }} />,
                      endAdornment: (
                        <InputAdornment position="end">
                          <RevaText id="minutos" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid2>

                <Grid2 size={{ xs: 12, md: 6 }}>
                  {this.state.spaces && (
                    <FormControl fullWidth>
                      <InputLabel id="space-select">{intl.formatMessage({ id: "Sede" })}</InputLabel>
                      <Select
                        label={intl.formatMessage({ id: "Sede" })}
                        defaultValue={this.state.selectedSpaceId}
                        disabled={this.state.spaces.length === 0}
                        startAdornment={<PlaceIcon style={{ fontSize: 16, marginRight: 5 }} />}
                        onChange={this.handleSpaceChange}
                      >
                        {this.state.spaces.map((space) => {
                          return (
                            <MenuItem key={space.id} value={space.id}>
                              {space.display_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  {!this.state.spaces && <CircularProgress size={14}></CircularProgress>}
                </Grid2>

                <Grid2 container size={{ xs: 12, md: 6 }}>
                  <Stack sx={{ flexGrow: 0, alignItems: "center" }}>
                    <RevaText id="Tiene vivo" variant="subtitle1" />
                    <Switch
                      checked={this.state.hasLive}
                      onChange={(event) => this.handleSwitchChange(event)}
                      color="primary"
                      name="hasLive"
                    />
                  </Stack>
                  <TextField
                    sx={{ flexGrow: 1 }}
                    disabled={!this.state.hasLive}
                    value={this.state.channel_name}
                    label={intl.formatMessage({ id: "Canal de streaming" })}
                    onChange={this.handleTextInputChange("channel_name")}
                  />
                </Grid2>
              </Grid2>
            ) : (
              <RevaText id="Podrás asignar la fecha, hora y sede a este partido una vez que comience el torneo." />
            )}
          </div>
        )}
        {canEdit && tournamentStarted && (
          <Grid2 container sx={{ justifyContent: "center" }}>
            <CTAButton sx={{ flex: 1, mt: 2, maxWidth: 400 }} onClick={() => this.update(onSubmitScore)}>
              {intl.formatMessage({ id: "Guardar" })}
            </CTAButton>
          </Grid2>
        )}
      </Stack>
    );
  }

  renderScoring() {
    const { matchInfo } = this.state;

    const { isBracketStage, onSubmitScore, classes, intl } = this.props;
    const { winner, playerScores } = this.state;
    const participants = [matchInfo.player1, matchInfo.player2];

    return (
      <Stack sx={{ width: "100%" }}>
        <Stack direction="row" sx={{ alignItems: "center", m: 2 }}>
          <RevaText id="En vivo ahora" style={{ marginRight: 20, fontWeight: 600 }}></RevaText>
          <Switch
            checked={this.state.liveNow}
            onChange={(event) => this.handleSwitchChange(event)}
            color="primary"
            name="liveNow"
          />
        </Stack>

        <Stack direction="row" sx={{ alignSelf: "flex-end" }}>
          <Button onClick={() => this.onSetChange("add")}>{intl.formatMessage({ id: "Agregar set" })}</Button>

          {playerScores.length > 1 && (
            <Button onClick={() => this.onSetChange("remove")}>{intl.formatMessage({ id: "Quitar set" })}</Button>
          )}
        </Stack>

        <div className={classes.scoreTableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, fontWeight: 600, flex: 1 }}>
                  {intl.formatMessage({ id: "Participante" })}
                </TableCell>
                {playerScores.map((set, setId) => (
                  <TableCell key={setId} style={{ fontSize: 16, fontWeight: 600 }}>
                    {playerScores.length > 1 ? `${setId + 1}` : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {participants.map((player, i) => (
                <TableRow key={player.data.id}>
                  <TableCell component="th" scope="row">
                    <RevaText style={{ fontSize: 14 }}>{player.data.name}</RevaText>
                  </TableCell>
                  {playerScores.map((set, setId) => (
                    <TableCell key={setId} component="th" scope="row">
                      <TextField
                        id="filled-bare"
                        key={`Set ${setId + 1}`}
                        className={classes.score}
                        InputProps={{
                          classes: {
                            root: classes.scoreInput,
                          },
                        }}
                        margin="normal"
                        type="number"
                        inputProps={{ min: 0 }}
                        variant="outlined"
                        defaultValue={set[i] || 0}
                        onChange={this.handleChange(setId, i)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <FormControlLabel
              control={<Checkbox checked={this.state.walkover} onChange={this.handleInputChange} name="walkover" />}
              label={intl.formatMessage({ id: "Ganado por walkover" })}
            />
          </Grid>
        </div>

        <Stack sx={{ alignItems: "center", mt: 2 }}>
          <RevaText id="¿Quién ganó?" variant="h4"></RevaText>
          <Stack sx={{ mt: 2, flexDirection: { xs: "column", md: "row" }, alignItems: "center", gap: 2 }}>
            <Button
              variant="contained"
              className={winner === participants[0].data.id ? classes.selectedWinnerButton : classes.pickWinnerButton}
              onClick={() => this.setState({ winner: participants[0].data.id })}
            >
              {participants[0].data.name}
            </Button>
            {!isBracketStage && (
              <Button
                variant="contained"
                className={winner === -1 ? classes.selectedWinnerButton : classes.pickWinnerButton}
                onClick={() => this.setState({ winner: -1 })}
              >
                {intl.formatMessage({ id: "Marcar como empate" })}
              </Button>
            )}

            <Button
              variant="contained"
              className={winner === participants[1].data.id ? classes.selectedWinnerButton : classes.pickWinnerButton}
              onClick={() => this.setState({ winner: participants[1].data.id })}
            >
              {participants[1].data.name}
            </Button>
          </Stack>
        </Stack>
        <Stack className={classes.submitScoreContainer}>
          <CTAButton
            variant="contained"
            color="secondary"
            disabled={!this.state.winner}
            sx={{ alignSelf: "center" }}
            onClick={() => this.updateMatch()}
          >
            {intl.formatMessage({ id: matchInfo.state === CLOSED ? "Actualizar ganador" : "Finalizar partido" })}
          </CTAButton>
        </Stack>
      </Stack>
    );
  }

  render() {
    const { matchInfo } = this.state;
    const { showScoreTab, classes, intl } = this.props;
    const { tabIndex, showScoreDetailsTab } = this.state;

    if (!this.state.ready || !matchInfo) {
      return (
        <Grid container justifyContent="center" alignContent="center">
          <CircularProgress size={24} />
        </Grid>
      );
    }

    return (
      <Stack sx={{ p: 3, borderRadius: 2 }}>
        <Tabs
          value={tabIndex}
          classes={{ flexContainer: classes.tabs }}
          indicatorColor="primary"
          textColor="inherit"
          onChange={(event, value) => this.setState({ tabIndex: value })}
        >
          <Tab label={intl.formatMessage({ id: "Información" })} />
          {showScoreTab && <Tab label={intl.formatMessage({ id: "Resultados" })} />}
          {showScoreTab && showScoreDetailsTab && <Tab label={intl.formatMessage({ id: "Detalles" })} />}
        </Tabs>
        {tabIndex === 0 && this.renderDetails()}

        {tabIndex === 1 && this.renderScoring()}

        {tabIndex === 2 && <MatchScoreDetails match={matchInfo} />}
      </Stack>
    );
  }
}

export default withUserContext(withStyles(styles)(injectIntl(EditMatchModal)));
