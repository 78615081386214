import React, { Component } from "react";
import performRequest from "network/perform-request";
import {
  competitionsShowRoute,
  competitionEstablishmentsAddRoute,
  competitionEstablishmentsDeleteRoute,
  userManagedEstablishmentsRoute,
} from "network/api-routes";
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  CardMedia,
  CardContent,
  CardActions,
  Box,
  Grid2,
  Stack,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import "moment/locale/es";
import AlertDialog from "../../common/AlertDialog";
import { Autocomplete } from "@mui/material";
import RevaText from "components/common/RevaText";
import { injectIntl } from "react-intl";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  createButton: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
  },
});

class CompetitionEstablishments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      ready: false,
      users: [],
      openModal: false,
      deleteOpen: false,
      establishmentOptions: [],
      selectedEstablishment: null,
      toDeleteEstablishment: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.fetchUserEstablishments();
  }

  fetch() {
    const { user } = this.props;

    performRequest("POST", competitionsShowRoute, { competition_uuid: this.state.id }, true, user)
      .then((response) => {
        const competition = response.data.data;
        this.setState({
          competition,
          establishments: competition.establishments.data,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchUserEstablishments() {
    const { user } = this.props;

    performRequest("POST", userManagedEstablishmentsRoute, {}, true, user)
      .then((response) => {
        const establishmentOptions = response.data.data;
        this.setState({ establishmentOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reset() {
    this.setState({
      selectedEstablishment: null,
      toDeleteEstablishment: null,
    });
  }

  submit() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionEstablishmentsAddRoute,
        {
          competition_uuid: this.state.id,
          establishment_id: this.state.selectedEstablishment.id,
        },
        true,
        user
      )
        .then((response) => {
          this.reset();

          this.fetch();
        })
        .catch((error) => {
          this.reset();
          this.fetch();
        });
    });
  }

  handleClose() {
    this.setState({ openModal: false });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderAddDialog() {
    const { intl } = this.props;

    return (
      <Box>
        <Dialog open={this.state.openModal} onClose={this.handleClose} fullWidth>
          <DialogTitle>{intl.formatMessage({ id: "Agregar un club" })}</DialogTitle>
          <DialogContent>
            <Autocomplete
              disableCloseOnSelect={false}
              multiple={false}
              value={this.state.selectedEstablishment}
              options={this.state.establishmentOptions}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  {/* <Avatar
                    key={option.id}
                    alt={option.public_name}
                    src={option.full_logo_url}
                    className={classes.avatar}
                  ></Avatar> */}
                  {option.public_name} - {option.country} ({option.active ? "Activo" : "Inactivo"})
                </li>
              )}
              getOptionLabel={(option) => option.public_name}
              isOptionEqualToValue={(option, value) => option.establishment_id === value.establishment_id}
              onChange={(event, value) => {
                this.setState({
                  selectedEstablishment: value,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({ id: "Selecciona un club para agregarlo" })}
                  variant="outlined"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>{intl.formatMessage({ id: "Cancelar" })}</Button>
            <Button onClick={this.submit}>{intl.formatMessage({ id: "Agregar" })}</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  delete() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionEstablishmentsDeleteRoute,
        {
          competition_uuid: this.state.id,
          establishment_id: this.state.toDeleteEstablishment.id,
        },
        true,
        user
      )
        .then((response) => {
          this.setState(
            {
              selectedUserInvite: null,
              ready: false,
            },
            () => {
              this.fetch();
            }
          );
        })
        .catch((error) => {
          this.fetch();
        });
    });
  }
  renderConfirmDeleteDialog() {
    return (
      <AlertDialog
        hideButton
        isOpen={this.state.deleteOpen}
        onConfirm={() => this.setState({ deleteOpen: false }, this.delete)}
        onCancel={() => this.setState({ deleteOpen: false })}
        alertBody="¿Estás seguro que quieres remover este local? Esto no modificará los partidos que ya tengan espacios asignados"
        confirmButtonTitle="Si, estoy seguro"
        cancelButtonTitle="Volver atrás"
      />
    );
  }

  render() {
    const { intl } = this.props;

    if (!this.state.ready) {
      return (
        <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
          <CircularProgress size={24}></CircularProgress>
        </Grid>
      );
    }

    return (
      <>
        <Stack spacing={2}>
          <RevaText id="Locales" variant="h2" />
          <RevaText id="Agrega locales a tu evento para utilizar sus canchas en tus partidos." variant="body1" />
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, sm: 6, lg: 4 }}>
              <Button
                color="secondary"
                sx={{
                  borderStyle: "dashed",
                  borderWidth: 2,
                  width: "100%",
                  height: "100%",
                  minHeight: 200,
                  flex: 1,
                  flexDirection: "column",
                }}
                onClick={() => this.setState({ openModal: true })}
              >
                <AddCircleOutlineIcon fontSize="large" sx={{ mb: 2 }} />
                <RevaText variant="h4">{intl.formatMessage({ id: "Agregar club" })}</RevaText>
              </Button>
            </Grid2>
            {this.state.establishments.map((establishment) => (
              <Grid2 size={{ xs: 12, sm: 6, lg: 4 }} key={establishment.id}>
                <Card>
                  <CardMedia
                    component="img"
                    height="140"
                    image={establishment.full_banner_url}
                    alt={establishment.public_name}
                  />
                  <CardContent sx={{ height: "max-content" }}>
                    <RevaText gutterBottom variant="h4" component="div">
                      {establishment.public_name}
                    </RevaText>
                    <RevaText variant="caption">{establishment.address}</RevaText>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() =>
                        this.setState({
                          toDeleteEstablishment: establishment,
                          deleteOpen: true,
                        })
                      }
                    >
                      {intl.formatMessage({ id: "Remover" })}
                    </Button>
                  </CardActions>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </Stack>
        {this.renderConfirmDeleteDialog()}
        {this.renderAddDialog()}
      </>
    );
  }
}

export default withStyles(styles)(injectIntl(CompetitionEstablishments));
