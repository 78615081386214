import { useState, Fragment, useEffect } from "react";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import modalities from "constants/modalities";
import { useIntl } from "react-intl";

const countries = require("../../../constants/ISO3166-1.alpha2.json");

const filter = createFilterOptions();

const CategoryAutocomplete = ({
  categories = [],
  selectedCategory = null,
  disabled = false,
  onCategorySelected = (category) => console.log(category),
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(selectedCategory);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    name: "",
    modality: modalities[0].slug,
  });

  // useEffect(() => {
  //   setValue(null);
  // }, [categories]);

  // Mapeo y clasificación en una sola pasada
  const { officialCategories, nonOfficialCategories } = categories.reduce(
    (acc, category) => {
      const countryName = countries[category.country] || category.country;
      const groupLabel = category.is_official ? `${countryName} - ${category.official_group}` : "Tus categorías";

      const formattedCategory = {
        ...category,
        group: groupLabel,
        index: category.is_official ? category.official_index : Infinity, // Para ordenar no oficiales al final
      };

      category.is_official
        ? acc.officialCategories.push(formattedCategory)
        : acc.nonOfficialCategories.push(formattedCategory);
      return acc;
    },
    { officialCategories: [], nonOfficialCategories: [] }
  );

  // Ordenamos primero por grupo y luego por índice
  officialCategories.sort((a, b) => a.group.localeCompare(b.group) || a.index - b.index);

  // Combinamos las listas
  const options = [...nonOfficialCategories, ...officialCategories];

  const handleClose = () => {
    setDialogValue({
      name: "",
      modality: modalities[0].slug,
    });
    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newCategory = {
      name: dialogValue.name,
      modality: dialogValue.modality,
    };
    setValue(newCategory);
    onCategorySelected(newCategory);
    handleClose();
  };

  return (
    <Fragment>
      <Autocomplete
        freeSolo
        selectOnFocus
        clearOnBlur
        value={value}
        options={options}
        groupBy={(option) => option.group}
        disabled={disabled}
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.name}
            </li>
          );
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string" || newValue?.inputValue) {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                ...dialogValue,
                name: newValue?.inputValue ?? newValue,
              });
            });
          } else {
            setValue(newValue?.name);
            onCategorySelected(newValue);
          }
        }}
        renderInput={(params) => <TextField {...params} required label={intl.formatMessage({ id: "Categoría" })} />}
        fullWidth
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Agregar una categoría nueva</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="Categoría"
            />
            <FormControl margin="dense">
              <InputLabel id="modalityLabel">{intl.formatMessage({ id: "Modalidad" })}</InputLabel>
              <Select
                id="modality"
                label="Modalidad"
                labelId="modalityLabel"
                value={dialogValue.modality}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    modality: event.target.value,
                  })
                }
              >
                {modalities.map((modality) => {
                  return (
                    <MenuItem key={modality.slug} value={modality.slug}>
                      {modality.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default CategoryAutocomplete;
