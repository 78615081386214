import {
  Stack,
  Paper,
  TextField,
  Box,
  Grid,
  Input,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CTAButton from "components/common/CTAButton";
import RevaText from "components/common/RevaText";
import UserContext from "context/UserContext";
import dayjs from "dayjs";
import { competitionsUpdateRoute } from "network/api-routes";
import performRequest from "network/perform-request";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";

const SectionContainer = ({ title, children }) => (
  <Paper elevation={2} sx={{ p: 4, borderRadius: 2 }}>
    {title && <RevaText id={title} variant="h4" mb={2} />}
    {children}
  </Paper>
);

export default function EditCompetitionForm({ competition }) {
  const { user } = useContext(UserContext);
  const intl = useIntl();
  const [startDate, setStartDate] = useState(dayjs(competition.start_at));
  const [endDate, setEndDate] = useState(dayjs(competition.end_at));
  const selectedCustomer = competition.customer?.data;
  const selectedAccount = competition.account?.data;
  const [formData, setFormData] = useState({
    ...competition,
    bannerUrl: competition.full_banner_url,
    coverUrl: competition.full_cover_url,
  });
  const [submitting, setSubmitting] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const stepTitles = [
    intl.formatMessage({ id: "Información básica" }),
    intl.formatMessage({ id: "Detalles del evento" }),
    intl.formatMessage({ id: "Opciones de pago" }),
    intl.formatMessage({ id: "Redes sociales" }),
  ];

  const handleFileUpload = (e, type, maxSize, errorMsg) => {
    const file = e.target.files[0];
    if (file && file.size > maxSize) {
      // setError(errorMsg);
      return;
    }

    if (type === "banner" || type === "cover") {
      let url = null;
      if (file) {
        url = URL.createObjectURL(file);
      }

      setFormData({ ...formData, [type]: file, [`${type}Url`]: url });
    } else {
      setFormData({ ...formData, [type]: file });
    }
  };

  const saveChanges = () => {
    const headers = { "Content-Type": "multipart/form-data" };
    const formDataToSend = new FormData();

    setSubmitting(true);

    // Recorrer cada propiedad del objeto y agregarla a FormData
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (formData[key] instanceof File) {
          switch (key) {
            case "banner":
              formDataToSend.append(key, formData[key], `${formData.name}-banner.jpg`);
              break;
            case "cover":
              formDataToSend.append(key, formData[key], `${formData.name}-cover.jpg`);
              break;
            case "regulations":
              formDataToSend.append(key, formData[key], `${formData.name}-regulations.pdf`);
              break;
            default:
              break;
          }
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }
    });

    formDataToSend.append("competition_id", competition.id);
    formDataToSend.delete("banner_url");
    formDataToSend.delete("cover_url");

    performRequest("POST", competitionsUpdateRoute, formDataToSend, true, user, null, headers)
      .then((response) => {
        setSubmitting(false);
        enqueueSnackbar(intl.formatMessage({ id: "Los cambios se guardaron correctamente" }), { variant: "success" });
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(
          error?.response?.data?.message || intl.formatMessage({ id: "No se pudieron guardar los cambios" }),
          {
            variant: "error",
          }
        );
        console.log(error);
      });
  };

  return (
    <Stack sx={{ gap: 3 }}>
      <RevaText id="Editar evento" variant="h2" sx={{ mb: 2 }} />

      <SectionContainer title={stepTitles[0]}>
        <TextField
          required
          label={intl.formatMessage({ id: "Nombre del evento" })}
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <TextField
          label={intl.formatMessage({ id: "Descripción" })}
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            columnGap: 3,
          }}
        >
          <TextField
            label={intl.formatMessage({ id: "Organizador" })}
            fullWidth
            margin="normal"
            value={formData.organizer}
            onChange={(e) => setFormData({ ...formData, organizer: e.target.value })}
          />
          <TextField
            label={intl.formatMessage({ id: "Teléfono del organizador" })}
            fullWidth
            margin="normal"
            value={formData.organizer_phone}
            onChange={(e) =>
              setFormData({
                ...formData,
                organizer_phone: e.target.value,
              })
            }
          />
        </Box>
      </SectionContainer>

      <SectionContainer title={stepTitles[1]}>
        <Stack sx={{ gap: 2 }}>
          <Grid container columnGap={2}>
            <Grid xs={12} sm>
              <DatePicker
                label={`${intl.formatMessage({ id: "Fecha de inicio" })}*`}
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setFormData({
                    ...formData,
                    start_at: dayjs(date).format("YYYY-MM-DD HH:mm:ss"),
                    end_at:
                      endDate && date && endDate < date
                        ? dayjs(date).add(1, "d").format("YYYY-MM-DD HH:mm:ss")
                        : formData.end_at,
                  });
                }}
                minDate={null}
                maxDate={endDate}
                slotProps={{
                  textField: { fullWidth: true, margin: "normal" },
                }}
              />
            </Grid>
            <Grid xs={12} sm>
              <DatePicker
                label={`${intl.formatMessage({ id: "Fecha de fin" })}*`}
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setFormData({
                    ...formData,
                    end_at: dayjs(date).add(1, "d").format("YYYY-MM-DD HH:mm:ss"),
                    start_at:
                      startDate && date && startDate > date
                        ? dayjs(date).format("YYYY-MM-DD HH:mm:ss")
                        : formData.start_at,
                  });
                }}
                minDate={startDate}
                slotProps={{
                  textField: { fullWidth: true, margin: "normal" },
                }}
              />
            </Grid>
          </Grid>

          <Grid container gap={2}>
            <Grid container xs={12} sm gap={2}>
              <Grid xs={12} justifyItems="center">
                <Box
                  sx={{
                    alignSelf: "center",
                    width: "216px",
                    height: "270px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    objectFit: "cover",
                    overflow: "hidden",
                    border: "2px dashed #ccc",
                  }}
                >
                  {formData.bannerUrl ? (
                    <img
                      src={formData.bannerUrl}
                      alt="Vista previa"
                      style={{
                        width: "216px",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <RevaText id="Formato 4:5" variant="h4" />
                  )}
                </Box>
              </Grid>
              <Grid xs>
                <RevaText id="Poster del torneo (.jpg)" variant="subtitle1" mb={2} />
                <Input
                  fullWidth
                  type="file"
                  inputProps={{ accept: "image/jpg" }}
                  onChange={(e) =>
                    handleFileUpload(
                      e,
                      "banner",
                      1048576,
                      intl.formatMessage({ id: "El poster no puede pesar más de 800KB" })
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid container xs={12} sm gap={2}>
              <Grid xs={12} justifyItems="center">
                <Box
                  sx={{
                    width: { xs: "100%", sm: "480px" },
                    aspectRatio: "16/9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    objectFit: "cover",
                    overflow: "hidden",
                    border: "2px dashed #ccc",
                  }}
                >
                  {formData.coverUrl ? (
                    <img
                      src={formData.coverUrl}
                      alt="Vista previa"
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <RevaText id="Formato 16:9" variant="h4" />
                  )}
                </Box>
              </Grid>
              <Grid xs>
                <RevaText id="Cover del torneo (.jpg)" variant="subtitle1" mb={2} />
                <Input
                  fullWidth
                  type="file"
                  inputProps={{ accept: "image/jpg" }}
                  onChange={(e) =>
                    handleFileUpload(
                      e,
                      "cover",
                      1048576,
                      intl.formatMessage({ id: "El cover no puede pesar más de 800KB" })
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Box width="100%">
            <RevaText id="Reglamento (.pdf)" variant="subtitle1" mb={2} />
            <Input
              fullWidth
              type="file"
              inputProps={{ accept: "application/pdf" }}
              onChange={(e) =>
                handleFileUpload(
                  e,
                  "regulations",
                  1000000,
                  intl.formatMessage({ id: "El reglamento no puede pesar más de 1MB" })
                )
              }
            />
          </Box>
        </Stack>
      </SectionContainer>

      <SectionContainer title={stepTitles[2]}>
        <Grid container gap={2}>
          <Grid xs={12} sm>
            <Autocomplete
              disabled
              sx={{ mt: 2 }}
              value={selectedCustomer}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label={intl.formatMessage({ id: "Selecciona un cliente" })} variant="outlined" />
              )}
            />
          </Grid>
          <Grid xs={12} sm>
            <Autocomplete
              disabled
              sx={{ mt: 2 }}
              value={selectedAccount}
              getOptionLabel={(option) => `[${option.currency_code}] ${option.name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "Selecciona una cuenta donde se registrarán los pagos y cobros",
                  })}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.only_online_payment}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      only_online_payment: e.target.checked,
                    })
                  }
                  value="onlyOnlinePayment"
                />
              }
              label={intl.formatMessage({ id: "Solo permitir pagos online" })}
            />
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.payqr_enabled}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      payqr_enabled: e.target.checked,
                    })
                  }
                />
              }
              label={intl.formatMessage({ id: "Pagos con QR" })}
            />
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.signups_expires}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      signups_expires: e.target.checked,
                    })
                  }
                />
              }
              label={intl.formatMessage({ id: "Inscripciones sin pago expiran" })}
            />
          </Grid>
          <Grid xs={12}>
            {formData.signups_expires && (
              <Box display="flex" alignItems="center" gap={2}>
                <InputLabel id="paymentTimeLimit">{intl.formatMessage({ id: "Tiempo para pagar" })}</InputLabel>
                <FormControl size="small">
                  <Select
                    sx={{ width: 150 }}
                    labelId="paymentTimeLimit"
                    disabled={!formData.signups_expires}
                    value={formData.signups_expires_after}
                    onChange={(e) => setFormData({ ...formData, signups_expires_after: e.target.value })}
                  >
                    <MenuItem value={60}>{intl.formatMessage({ id: "1 hora" })}</MenuItem>
                    <MenuItem value={120}>{intl.formatMessage({ id: "2 horas" })}</MenuItem>
                    <MenuItem value={180}>{intl.formatMessage({ id: "3 horas" })}</MenuItem>
                    <MenuItem value={720}>{intl.formatMessage({ id: "12 horas" })}</MenuItem>
                    <MenuItem value={1440}>{intl.formatMessage({ id: "1 día" })}</MenuItem>
                    <MenuItem value={2880}>{intl.formatMessage({ id: "2 dias" })}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
          </Grid>
        </Grid>
      </SectionContainer>

      <SectionContainer title={stepTitles[3]}>
        <TextField
          label={intl.formatMessage({ id: "Link de WhatsApp" })}
          fullWidth
          margin="normal"
          value={formData.whatsapp_group_url}
          onChange={(e) =>
            setFormData({
              ...formData,
              whatsapp_group_url: e.target.value,
            })
          }
        />
        <TextField
          label={intl.formatMessage({ id: "Link de Discord" })}
          fullWidth
          margin="normal"
          value={formData.discord_url}
          onChange={(e) => setFormData({ ...formData, discord_url: e.target.value })}
        />
        <TextField
          label={intl.formatMessage({ id: "Link de formulario" })}
          fullWidth
          margin="normal"
          value={formData.form_url}
          onChange={(e) => setFormData({ ...formData, form_url: e.target.value })}
        />
      </SectionContainer>

      <Box sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "flex-end" } }}>
        <CTAButton loading={submitting} variant="contained" onClick={() => saveChanges()}>
          {intl.formatMessage({ id: "Guardar cambios" })}
        </CTAButton>
      </Box>
    </Stack>
  );
}
