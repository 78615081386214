const tournamentTypes = {
  "single-elimination": {
    name: "Eliminación Directa",
  },
  "double-elimination": {
    name: "Doble Eliminación",
  },
};

export const SINGLE_ELIMINATION = "single-elimination";
export const DOUBLE_ELIMINATION = "double-elimination";
export default tournamentTypes;
