import { forwardRef } from "react";
import { useIntl } from "react-intl";
// import { LanguageContext } from "../../context/LanguageProvider";
import Typography from "@mui/material/Typography";

const RevaText = forwardRef(({ children, id, values, ...props }, ref) => {
  const intl = useIntl();
  const defaultMessage = id;

  return (
    <Typography ref={ref} {...props}>
      {id ? intl.formatMessage({ id, defaultMessage }, values) : children} {/* Si no hay ID, renderiza los children */}
    </Typography>
  );
});

RevaText.displayName = "RevaText";

export default RevaText;
